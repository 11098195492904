import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { Helmet } from 'react-helmet-async'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'
import moment from 'moment'
import Select, { ActionMeta, SingleValue } from 'react-select'
import Sticky from 'react-stickynode'
import styled from 'styled-components'

import {
  Colors,
  getTranscodeUrl,
  isFile,
  isVideo,
  parseUrls,
  sleep,
} from '@sportsyou/core'
import { Pencil, Plus, X } from '@sportsyou/react-icons'
import {
  Button,
  DateRange,
  FileTypeIcon,
  Icon,
  Post as PostComponent,
  Spinner,
  TextArea,
  TextInput,
  useDialog,
} from '@sportsyou/react-dom-ui'
import {
  ExtendedFile,
  ExtendedUpload,
  UploaderProps,
  useFetchApi,
  useUploader,
} from '@sportsyou/react-hooks'
import {
  Image as ApiImageProp,
  Location,
  MutationPostCommentsEnableRequest,
  MutationPostCreateArgs,
  MutationPostUpdateArgs,
  MutationUploadUpdateVideoPosterImageRequest,
  Post as ApiPostProp,
  QuerySponsoredPostReachArgs,
  Upload,
  User,
  mutationPostCommentsEnable,
  mutationPostCreate,
  mutationPostDelete,
  mutationPostUpdate,
  mutationUploadDelete,
  mutationUploadUpdateVideoPosterImage,
  queryMetroAreas,
  queryPost,
  querySponsoredPostReach,
  querySports,
  queryUploadUrl,
  queryUser,
  PostMetadata,
  GetMetadataRequest,
  CacheOptions,
  GetMetadataResponse,
  getMetadata,
  queryCommunity,
  QueryCommunityRequest,
} from '@sportsyou/api'

import {
  Header,
  HeaderTitle,
  TableTools,
  TableToolsLeft,
  TableToolsRight,
} from '.'

import LocationPickerItem from './components/LocationPickerItem'
import {
  ContentContainer,
  HeaderTitleChevron,
} from '../../styles/global-styles'
import VideoThumbnailUpdateModal from './components/VideoThumbnailUpdateModal'
import {
  selectSponsoredPostById,
  setSponsoredPost,
} from '../../store/slices/SponsoredPostsSlice'
import { selectCurrentUser } from '../../store/slices'

interface Option {
  label: string
  value: string
}

enum PostType {
  Media,
  Color,
}

const postTypeOptions: Option[] = [
  { label: 'Media Post', value: '0' },
  { label: 'Color Post', value: '1' },
]

const audienceUserTypesOptions: Option[] = [
  { label: 'Admins', value: 'admin' },
  { label: 'Non-Admins', value: 'non-admin' },
]

const audienceGendersOptions: Option[] = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Other', value: 'other' },
]

const audienceRolesOptions: Option[] = [
  { label: 'Coaches', value: 'coach' },
  { label: 'Players', value: 'player' },
  { label: 'Family', value: 'parent' },
  { label: 'Others', value: 'other' },
]

const priorityOptions: Option[] = [
  { label: '1-Very High', value: '01' },
  { label: '2-High', value: '02' },
  { label: '3-Medium', value: '03' },
  { label: '4-Low', value: '04' },
  { label: '5-Very Low', value: '05' },
]

export default function SponsoredPostsDetail({ tab }: { tab?: string }) {
  const { sendBanner, sendConfirm } = useDialog()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const uploader = useUploader.useUploader()

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: 50 * (1024 * 1024 * 1024), // 50GB
    onDropAccepted: onDropFileAccepted,
    onDropRejected: onDropFileRejected,
    useFsAccessApi: false,
  })

  const existingPost =
    useSelector((state) => selectSponsoredPostById(state, params.id)) ?? {}
  const _currentUser = useSelector(selectCurrentUser)

  const [audienceUserTypes, setAudienceUserTypes] = useState<Option[]>([])
  const [audienceGenders, setAudienceGenders] = useState<Option[]>([])
  const [audienceRoles, setAudienceRoles] = useState<Option[]>([])
  const [audienceSports, setAudienceSports] = useState<Option[]>()
  const [audienceSportsOptions, setAudienceSportsOptions] = useState<Option[]>()
  const [audienceMetroAreas, setAudienceMetroAreas] = useState<any[]>([])
  const [audienceMetroAreaOptions, setAudienceMetroAreaOptions] = useState<
    any[]
  >([])
  const [priorities, setPriorities] = useState<Option[]>([
    { label: '3-Medium', value: '03' },
  ])
  const [canCreatePost, setCanCreatePost] = useState(false)
  const [canUpdateAliasUser, setCanUpdateAliasUser] = useState(false)
  const [currentUser, setCurrentUser] = useState<User>(_currentUser)
  const [isEditable, setIsEditable] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingReach, setIsLoadingReach] = useState(false)
  const [isNew] = useState(() => !params.id)
  const [isSaving, setIsSaving] = useState(false)
  const [metroAreas, setMetroAreas] = useState<any[]>([])
  const [metadata, setMetadata] = useState<PostMetadata[]>([])
  const [post, setPost] = useState<ApiPostProp | null>(existingPost)
  const [postType, setPostType] = useState<PostType>(() => {
    return existingPost?.color ? PostType.Color : PostType.Media
  })
  const [reach, setReach] = useState<number | undefined>(undefined)
  const [sports, setSports] = useState<any[]>([])
  const [userFeatures] = useState<string[]>(() =>
    JSON.parse(localStorage.getItem('user-features') ?? '[]')
  )
  const [endDate, setEndDate] = useState<Date>(
    new Date(
      existingPost.sponsoredPostInfo?.endDate ??
        moment().add(30, 'days').endOf('day').toDate()
    )
  )
  const [startDate, setStartDate] = useState<Date>(() =>
    moment().startOf('day').toDate()
  )

  const [videoUploadsInProgress, setVideoUploadsInProgress] =
    useState<ExtendedUpload[]>()
  const [openVideoThumbnailUpdateModal, setOpenVideoThumbnailUpdateModal] =
    useState(false)
  const [videoFile, setVideoFile] = useState<Upload | undefined>()
  const [allowComments, setAllowComments] = useState(false)

  const videosUpdated = useRef(new Map<string, Upload>())

  useEffect(() => {
    if (userFeatures.includes('SPONSORED_POST_ALLOW_ALIAS')) {
      setCanUpdateAliasUser(true)
    }
    if (userFeatures.includes('SPONSORED_POST_CREATE')) {
      setCanCreatePost(true)
    }
  }, [userFeatures])

  const { fetch: createPost } = useFetchApi(mutationPostCreate)
  const { fetch: deletePost } = useFetchApi(mutationPostDelete)
  const { fetch: getCommunity } = useFetchApi(queryCommunity)
  const { fetch: getPost } = useFetchApi(queryPost)
  const { fetch: getSponsoredPostReach } = useFetchApi(querySponsoredPostReach)
  const { fetch: getSports } = useFetchApi(querySports)
  const { fetch: getMetroAreas } = useFetchApi(queryMetroAreas)
  const { fetch: getUploadUrl } = useFetchApi(queryUploadUrl)
  const { fetch: getUser } = useFetchApi(queryUser)
  const { fetch: updatePost } = useFetchApi(mutationPostUpdate)
  const { fetch: updatePosterImage } = useFetchApi(
    mutationUploadUpdateVideoPosterImage
  )
  const { fetch: updateAllowComments } = useFetchApi(mutationPostCommentsEnable)
  const { fetch: removeMedia } = useFetchApi(mutationUploadDelete)

  const buildSportsOptions = useCallback((_sports?: any[]) => {
    return (
      _sports?.map((sport) => ({
        label: sport.name,
        value: sport.id,
      })) ?? []
    )
  }, [])

  const buildMetroAreaOptions = useCallback((_metroAreas?: any[]) => {
    return (
      _metroAreas?.map((area) => ({
        label: area,
        value: area,
      })) ?? []
    )
  }, [])

  const fetchData = useCallback(async () => {
    if (isNew) {
      setPost({
        message: '',
        sponsoredPostInfo: {
          feedPosition: 'float-first',
          openWebLinkForImages: false,
        },
        createdBy: {
          id: currentUser?.id,
          fullName: currentUser?.fullName,
          profileImage: currentUser?.profileImage,
        },
      })
      setAllowComments(false)
      setSports((await getSports()).data ?? [])
      setMetroAreas((await getMetroAreas()).data ?? [])
      setEndDate(moment().add(30, 'days').endOf('day').toDate())
      setIsEditable(true)
      setStartDate(moment().startOf('day').toDate())
    } else {
      const { data } = await getPost({
        id: params.id,
      })
      if (data) {
        if (data.sponsoredPostInfo?.origClickThroughUrl) {
          data.sponsoredPostInfo.clickThroughUrl =
            data.sponsoredPostInfo.origClickThroughUrl
        }

        setPost(data)
        setStartDate(
          new Date(
            data.sponsoredPostInfo?.startDate ??
              moment().startOf('day').toDate()
          )
        )
        setEndDate(
          new Date(
            data.sponsoredPostInfo?.endDate ??
              moment().add(30, 'days').endOf('day').toDate()
          )
        )
        setAllowComments(!!data?.allowComments)
        dispatch(setSponsoredPost(data))

        const hydrateAudience = (
          setter: any,
          values: any[],
          options: any[]
        ) => {
          setter(
            values.map((value) =>
              options.find((option) => option.value === value)
            )
          )
        }

        hydrateAudience(
          setAudienceGenders,
          data.sponsoredPostInfo?.selections?.genders ?? [],
          audienceGendersOptions
        )
        hydrateAudience(
          setAudienceRoles,
          data.sponsoredPostInfo?.selections?.teamRoles ?? [],
          audienceRolesOptions
        )
        hydrateAudience(
          setAudienceUserTypes,
          data.sponsoredPostInfo?.selections?.userTypes ?? [],
          audienceUserTypesOptions
        )

        const _sports = (await getSports()).data ?? []
        setSports(_sports)

        setIsEditable(data.canEdit || false)
        hydrateAudience(
          setAudienceSports,
          data.sponsoredPostInfo?.selections?.sportIds ?? [],
          buildSportsOptions(_sports)
        )

        const _metroAreas = (await getMetroAreas()).data ?? []
        setMetroAreas(_metroAreas)
        hydrateAudience(
          setAudienceMetroAreas,
          data.sponsoredPostInfo?.selections?.metroAreas ?? [],
          buildMetroAreaOptions(_metroAreas)
        )

        hydrateAudience(
          setPriorities,
          [data.sponsoredPostInfo?.priority],
          priorityOptions
        )
      }
    }
    setIsLoading(false)
  }, [
    buildMetroAreaOptions,
    buildSportsOptions,
    currentUser?.fullName,
    currentUser?.id,
    currentUser?.profileImage,
    dispatch,
    getMetroAreas,
    getPost,
    getSports,
    isNew,
    params.id,
  ])

  const fetchMetadata = useDebouncedCallback(async () => {
    const urls = parseUrls(post?.message as string)
    const md: PostMetadata[] = []
    for (const url of urls) {
      const req: GetMetadataRequest = {
        url,
        requestOptions: {
          useCache: CacheOptions.ALWAYS,
        },
      }
      const resp: GetMetadataResponse = await getMetadata(req)
      if (!resp?.error) {
        resp?.data && md.push(resp.data)
      }
    }
    setMetadata(md)
  }, 1000)

  useEffect(() => {
    if (post?.message && !post?.color) {
      fetchMetadata()
    }
  }, [fetchMetadata, post?.color, post?.message])

  useEffect(() => {
    const _sports = buildSportsOptions(sports)
    setAudienceSportsOptions(_sports)

    const _metroAreas = buildMetroAreaOptions(metroAreas)
    setAudienceMetroAreaOptions(_metroAreas)
  }, [buildMetroAreaOptions, buildSportsOptions, isNew, metroAreas, sports])

  function onDropFileRejected(
    fileRejections: FileRejection[],
    event: DropEvent
  ): void {
    console.log({ fileRejections, event })
  }

  async function onDropFileAccepted(files: ExtendedFile[]): Promise<void> {
    upload(files)
  }

  // function encode(input: Uint8Array) {
  //   const keyStr =
  //     'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
  //   let output = ''
  //   let chr1, chr2, chr3, enc1, enc2, enc3, enc4
  //   let i = 0

  //   while (i < input.length) {
  //     chr1 = input[i++]
  //     chr2 = i < input.length ? input[i++] : Number.NaN // Not sure if the index
  //     chr3 = i < input.length ? input[i++] : Number.NaN // checks are needed here

  //     enc1 = chr1 >> 2
  //     enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
  //     enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
  //     enc4 = chr3 & 63

  //     if (isNaN(chr2)) {
  //       enc3 = enc4 = 64
  //     } else if (isNaN(chr3)) {
  //       enc4 = 64
  //     }
  //     output +=
  //       keyStr.charAt(enc1) +
  //       keyStr.charAt(enc2) +
  //       keyStr.charAt(enc3) +
  //       keyStr.charAt(enc4)
  //   }
  //   return output
  // }

  async function upload(files: ExtendedFile[]): Promise<void> {
    /**
     * build upload previews
     */
    const uploadParams: UploaderProps = {
      enableChunk: false,
      files,
      onError: onUploaderError,
      onProgress: onUploaderProgress,
      onUploadDone: onUploaderUploadDone,
      shouldCreateHLS: true,
      // onCancel: onUploaderCancel,
      // onComplete: onUploaderComplete,
      // onUploadStart: onUploaderUploadStart,
    }
    const uploadPreviews: ExtendedUpload[] = await Promise.all(
      files.map(async (file, index) => {
        const localId = useUploader.generateUUID(2)
        const viewUrl =
          (/^image\/(jp?eg|png|gif|webp)/.test(file.type) &&
            window.URL.createObjectURL(file as Blob)) ||
          undefined
        file.localId = localId
        return {
          contentType: file.type,
          file,
          fileName: file.name,
          id: localId,
          localId,
          progress: {
            clientProgress: 0,
            uploadProgress: 0,
          },
          uploaderProp: uploadParams,
          viewUrl,
        }
      })
    )
    // console.log({ uploadPreviews })
    const existingUploads = [...(post?.uploads ?? [])] // save existing uploads before adding previews
    onChangePostInfo('uploads', uploadPreviews)

    /**
     * start uploading process
     */
    uploader.reset()
    const uploads = await uploader.create(uploadParams)
    // console.log({ uploads })

    /**
     * clears uploads and add back previews merged with uploads
     */
    const merged = mergePreviewsToUploads(uploadPreviews, uploads)
    // console.log({ merged })
    onChangePostInfo('uploads', [])
    onChangePostInfo('uploads', [...existingUploads, ...merged])
  }

  function mergePreviewsToUploads(
    previews: ExtendedUpload[],
    uploads: ExtendedUpload[]
  ): ExtendedUpload[] {
    return uploads.map((up) => {
      const viewUrl = previews.find((pv) => pv.localId === up.localId)?.viewUrl
      return {
        ...up,
        viewUrl,
      }
    })
  }

  const buildAudience = useCallback(
    (post: ApiPostProp) => {
      const filterBlankLocations = (locations: Location[]) => {
        return locations.filter((location) => {
          return (
            !!location.city?.trim().length ||
            !!location.stateProvince?.trim().length ||
            !!location.postalCode?.trim().length ||
            !!location.country?.trim().length
          )
        })
      }

      return {
        genders: audienceGenders?.map((a) => a.value) ?? [],
        locations: filterBlankLocations(
          (post?.sponsoredPostInfo?.selections?.locations as Location[]) ?? []
        ),
        maxAge: parseInt(
          (post?.sponsoredPostInfo?.selections?.maxAge ?? 200).toString(),
          10
        ),
        minAge: parseInt(
          (post?.sponsoredPostInfo?.selections?.minAge ?? 13).toString(),
          10
        ),
        sportIds: audienceSports?.map((a) => parseInt(a.value, 10)) ?? [],
        teamRoles: audienceRoles?.map((a) => a.value) ?? [],
        userTypes: audienceUserTypes?.map((a) => a.value) ?? [],
        metroAreas: audienceMetroAreas?.map((a) => a.value) ?? [],
      }
    },
    [
      audienceGenders,
      audienceRoles,
      audienceSports,
      audienceUserTypes,
      audienceMetroAreas,
    ]
  )

  const onClickUpload = useCallback(() => {
    if (post?.sponsoredPostInfo?.clickThroughUrl) {
      window.open(post?.sponsoredPostInfo.clickThroughUrl, '_blank')
    }
  }, [post])

  const handleOnClickRefreshReach = useCallback(async () => {
    setIsLoadingReach(true)
    const params: QuerySponsoredPostReachArgs = {
      criteria: buildAudience(post ?? {}),
    }
    const { data, ok } = await getSponsoredPostReach(params)
    if (ok) {
      setReach(data?.activeUserCount ?? undefined)
    } else {
      sendBanner({
        autoDismiss: true,
        dismissTime: 2000,
        message: 'Could not refresh reach at this time.',
        status: 'alert',
        // type: 'card',
      })
      console.log('error', data)
    }
    setIsLoadingReach(false)
  }, [buildAudience, getSponsoredPostReach, post])

  const onClickSave = useCallback(async () => {
    if (!uploader.checkAllComplete()) {
      sendBanner({
        autoDismiss: true,
        dismissTime: 2000,
        status: 'alert',
        message: 'Please wait for all uploads to complete',
        // type: 'card',
      })
      return
    }

    const makeCreateRequestData = (
      post: ApiPostProp
    ): MutationPostCreateArgs => {
      return {
        allowComments,
        color: post.color,
        message: post.message,
        postTypes: ['user'],
        targetIds: [currentUser?.id as string],
        sponsoredPostInfo: {
          adName: post.sponsoredPostInfo?.adName,
          aliasUserId: post.sponsoredPostInfo?.aliasUser?.id,
          buttonText: post.sponsoredPostInfo?.buttonText,
          clickThroughText: post.sponsoredPostInfo?.clickThroughText,
          clickThroughUrl: post.sponsoredPostInfo?.clickThroughUrl,
          communityId: post.sponsoredPostInfo?.communityId,
          feedPosition: post.sponsoredPostInfo?.feedPosition,
          headline: post.sponsoredPostInfo?.headline,
          openWebLinkForImages: post.sponsoredPostInfo?.openWebLinkForImages,
          selections: buildAudience(post),
          tagline: post.sponsoredPostInfo?.tagline,
          viewOnlyOnce: post.sponsoredPostInfo?.viewOnlyOnce,
          priority: priorities?.length ? priorities[0].value : null,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        uploads: post?.uploads?.map((u) => u?.id as string) ?? [],
      }
    }

    const makeUpdateRequestData = (
      post: ApiPostProp
    ): MutationPostUpdateArgs => {
      return {
        message: post.message,
        color: post.color,
        postId: post.id,
        sponsoredPostInfo: {
          adName: post.sponsoredPostInfo?.adName,
          aliasUserId: post.sponsoredPostInfo?.aliasUser?.id,
          buttonText: post.sponsoredPostInfo?.buttonText,
          clickThroughText: post.sponsoredPostInfo?.clickThroughText,
          clickThroughUrl: post.sponsoredPostInfo?.clickThroughUrl,
          communityId: post.sponsoredPostInfo?.communityId,
          headline: post.sponsoredPostInfo?.headline,
          openWebLinkForImages: post.sponsoredPostInfo?.openWebLinkForImages,
          selections: buildAudience(post),
          tagline: post.sponsoredPostInfo?.tagline,
          feedPosition: post.sponsoredPostInfo?.feedPosition,
          viewOnlyOnce: post.sponsoredPostInfo?.viewOnlyOnce,
          priority: priorities?.length ? priorities[0].value : null,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
      }
    }

    setIsSaving(true)
    if (isNew) {
      const _post = makeCreateRequestData(post ?? {})
      const { ok, error } = await createPost(_post)
      if (ok) {
        sendBanner({
          autoDismiss: true,
          dismissTime: 2000,
          message: 'Sponsored post created',
          status: 'success',
          // type: 'card',
        })
        navigate(`/sponsored-posts/`)
      } else {
        sendBanner({
          autoDismiss: true,
          dismissTime: 2000,
          message: `Could not create sponsored post${
            error ? ' - ' + error.toString() : ''
          }`,
          status: 'alert',
          // type: 'card',
        })
      }
    } else {
      if (post) {
        const _post = makeUpdateRequestData(post)
        const { ok, error } = await updatePost(_post)
        if (ok) {
          if (allowComments !== post.allowComments) {
            const res = await updateAllowComments({
              postId: post.id,
              enabled: allowComments,
            } as MutationPostCommentsEnableRequest)
          }

          console.log('saved', _post)
          sendBanner({
            autoDismiss: true,
            dismissTime: 2000,
            status: 'alert',
            message: 'Saved sponsored post',
            // type: 'card',
          })
          navigate(`/sponsored-posts/${post?.id}`)
        } else {
          sendBanner({
            autoDismiss: true,
            dismissTime: 2000,
            status: 'alert',
            message: `Could not save sponsored post${
              error ? ' - ' + error.toString() : ''
            }`,
            // type: 'card',
          })
        }

        // video poster image update
        if (videosUpdated.current.size > 0) {
          videosUpdated.current.forEach(async (image, videoId) => {
            const res = await updatePosterImage({
              uploadId: videoId,
              posterImageId: image.id,
            } as MutationUploadUpdateVideoPosterImageRequest).catch((error) =>
              console.error(error)
            )
            if (res?.ok) {
              //
            } else {
              console.error(res?.error)
            }
          })
        }
      }
    }
    setIsSaving(false)
  }, [
    allowComments,
    buildAudience,
    createPost,
    currentUser?.id,
    endDate,
    isNew,
    navigate,
    post,
    priorities,
    updatePost,
  ])

  const onClickDelete = useCallback(async () => {
    const { ok } = await deletePost({
      postId: params.id,
    })
    if (ok) {
      navigate('/sponsored-posts')
    }
  }, [deletePost, navigate, params.id])

  const onClickCancelButton = useCallback(
    (e: any) => {
      e.preventDefault()
      sendConfirm({
        confirmText: 'Ok',
        isDestructive: true,
        message: 'Go back without saving changes?',
        onConfirm: () => {
          navigate(isNew ? '/sponsored-posts' : `/sponsored-posts/${post?.id}`)
        },
      })
    },
    [isNew, navigate, post?.id, sendConfirm]
  )

  const onChangePostInfo = useCallback((field: string, value: any) => {
    setPost((prevPost) => {
      let newValue = value
      if (field === 'uploads') {
        const newSet = [...(prevPost?.uploads ?? [])]
        const incomingUploads = Array.isArray(newValue)
          ? [...newValue]
          : newValue
          ? [newValue]
          : []

        if (!newSet.length) {
          newSet.push(...incomingUploads)
        } else if (!incomingUploads.length) {
          // supplying undefined or an empty array empties out post.uploads
          newSet.splice(0)
        } else {
          for (const upload of incomingUploads) {
            const existingSetIndex = newSet.findIndex(
              (up) => up?.id === upload.id
            )
            if (existingSetIndex > -1) {
              newSet.splice(existingSetIndex, 1, upload)
            } else {
              newSet.push(upload)
            }
          }
        }

        newValue = newSet
      }
      return {
        ...prevPost,
        [field]: newValue,
      }
    })
  }, [])

  const onChangeSponsoredPostInfo = useCallback(
    async (key: string, value?: any) => {
      let community = post?.community

      if (key === 'communityId' && value) {
        const { data } = await getCommunity({
          idOrUrl: value,
        } as QueryCommunityRequest)
        if (data) {
          community = data
        }
      }

      setPost({
        ...post,
        community,
        sponsoredPostInfo: {
          ...post?.sponsoredPostInfo,
          [key]: value,
        },
      })
    },
    [post]
  )

  const onChangeAudience = useCallback(
    (key: string, value?: any) => {
      setPost({
        ...post,
        sponsoredPostInfo: {
          ...post?.sponsoredPostInfo,
          selections: {
            ...post?.sponsoredPostInfo?.selections,
            [key]: value,
          },
        },
      })
      setReach(undefined)
    },
    [post]
  )

  const onAddAnotherLocation = useCallback(() => {
    const locations = [
      ...((post?.sponsoredPostInfo?.selections?.locations as Location[]) ?? []),
    ]
    locations.push({})
    onChangeAudience('locations', locations)
  }, [onChangeAudience, post?.sponsoredPostInfo?.selections?.locations])

  const onChangeLocationPickerItem = useCallback(
    (location: Location, index: number) => {
      const locations = [
        ...((post?.sponsoredPostInfo?.selections?.locations as Location[]) ??
          []),
      ]
      locations[index] = location
      onChangeAudience('locations', locations)
    },
    [onChangeAudience, post?.sponsoredPostInfo?.selections?.locations]
  )

  async function onCloseVideoThumbnailUpdateModal(
    updated?: boolean,
    video?: Upload,
    image?: Upload
  ) {
    setOpenVideoThumbnailUpdateModal(false)

    if (
      !updated ||
      !post?.uploads?.length ||
      !video?.id ||
      (!isNew && !image?.id)
    ) {
      return
    }

    if (isNew) {
      const { data: upload } = await getUploadUrl({ uploadId: video.id })
      if (upload) {
        const uploads = [...(post?.uploads ?? [])]
        const videoIndex = uploads.findIndex((up) => up?.id === video.id)
        uploads.splice(videoIndex, 1, upload)
        console.log({ videoIndex, uploads })
        onChangePostInfo('uploads', uploads)
      }
    } else {
      videosUpdated.current.set(video.id, image!)
      const uploads = [...(post?.uploads ?? [])]
      const videoIndex = uploads.findIndex((up) => up?.id === video.id)
      if (videoIndex === -1) return
      uploads.splice(videoIndex, 1, video)
      onChangePostInfo('uploads', uploads)
    }
  }

  const onRemoveLocationPickerItem = useCallback(
    (index: number) => {
      const locations = [
        ...((post?.sponsoredPostInfo?.selections?.locations as Location[]) ??
          []),
      ]
      locations.splice(index, 1)
      onChangeAudience('locations', locations)
    },
    [onChangeAudience, post?.sponsoredPostInfo?.selections?.locations]
  )

  const onRemoveUpload = useCallback(
    (
      e: React.MouseEvent<SVGSVGElement, MouseEvent>,
      index: number,
      upload?: Upload
    ) => {
      e.preventDefault()
      e.stopPropagation()
      upload?.id && uploader.abort(upload?.id)
      const uploads = [...(post?.uploads ?? [])].filter(
        (up) => up?.id !== upload?.id
      )
      setPost({
        ...post,
        uploads,
      })
    },
    [post, uploader]
  )

  const onVideoEdit = useCallback(
    (
      e: React.MouseEvent<SVGSVGElement, MouseEvent>,
      index: number,
      upload?: Upload
    ) => {
      e.preventDefault()
      e.stopPropagation()
      // console.log('pencil icon clicked', index, upload)
      if (upload?.id && !openVideoThumbnailUpdateModal) {
        setVideoFile(upload)
        setOpenVideoThumbnailUpdateModal(true)
      }
    },
    [openVideoThumbnailUpdateModal]
  )

  const onUploaderProgress = useCallback(
    async (_uploads: ExtendedUpload[], currentUpload: ExtendedUpload) => {
      setPost((prevPost) => {
        const uploads = [...(prevPost?.uploads ?? [])]
        const currentUpIndex = uploads.findIndex(
          (up) => up?.id === currentUpload.id
        )
        if (currentUpIndex > -1) {
          const viewUrl = uploads[currentUpIndex]?.viewUrl
          if (viewUrl) {
            uploads.splice(currentUpIndex, 1, {
              ...currentUpload,
              viewUrl,
            })
          }
        }
        return {
          ...prevPost,
          uploads,
        }
      })
    },
    []
  )

  const onUploaderError = useCallback(
    async (_uploads: ExtendedUpload[], cancelledUpload: ExtendedUpload) => {
      const { progress } = cancelledUpload
      const failedStep = progress?.clientProgress !== 100 ? 'upload' : 'process'

      setPost((prevPost) => ({
        ...prevPost,
        uploads: prevPost?.uploads?.filter(
          (up) => up?.id !== cancelledUpload.id
        ),
      }))

      await removeMedia({ id: cancelledUpload.id })
      sendBanner({
        autoDismiss: false,
        status: 'alert',
        message: `Item "${
          cancelledUpload.fileName ?? ''
        }" failed to ${failedStep}.`,
      })
    },
    []
  )

  const onUploaderUploadDone = useCallback(
    async (_uploads: ExtendedUpload[], currentUpload: ExtendedUpload) => {
      await sleep(2_000)
      const { data: upload, ok } = await getUploadUrl({
        uploadId: currentUpload.id,
      })
      if (ok && upload) {
        onChangePostInfo('uploads', [upload])
      }
    },
    [onChangePostInfo]
  )

  const onPostTypeChange = useCallback(
    (newValue: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
      const newIndex = parseInt(newValue?.value ?? '', 10)
      const color = post?.color?.length ? post?.color : Colors.HAVELOCK_BLUE
      if (newIndex === 0) {
        sendConfirm({
          confirmText: 'Ok',
          isDestructive: true,
          message:
            'Changing to a media post will remove the post color. Are you sure you want to continue?',
          onConfirm: () => {
            onChangePostInfo('color', null)
            setPostType(PostType.Media)
          },
        })
      } else if (newIndex === 1) {
        if (post?.uploads?.length) {
          sendConfirm({
            confirmText: 'Ok',
            isDestructive: true,
            message:
              'Changing to a color post will remove any media you have uploaded. Are you sure you want to continue?',
            onConfirm: () => {
              onChangePostInfo('color', color)
              onChangePostInfo('uploads', [])
              setPostType(PostType.Color)
            },
          })
        } else {
          onChangePostInfo('color', color)
          setPostType(PostType.Color)
        }
      }
    },
    [onChangePostInfo, post, sendConfirm]
  )

  useEffect(() => {
    async function checkUser() {
      const res = await getUser().catch((err) => console.error(err))
      const userData: User = res?.data as User
      setCurrentUser(userData)
    }

    if (currentUser) {
      fetchData()
    } else {
      checkUser()
    }
  }, [currentUser, fetchData, getPost, getUser])

  useEffect(() => {
    const fetchAliasUser = async () => {
      const { data: aliasUser } = await getUser({
        id: post?.sponsoredPostInfo?.aliasUser?.id,
      })
      if (aliasUser) {
        onChangePostInfo('createdBy', aliasUser)
      }
    }
    if (
      post?.sponsoredPostInfo?.aliasUser?.id &&
      currentUser?.id &&
      post?.sponsoredPostInfo?.aliasUser?.id !== currentUser?.id
    ) {
      fetchAliasUser()
    } else {
      onChangePostInfo('createdBy', currentUser)
    }
  }, [
    currentUser,
    currentUser?.id,
    getUser,
    onChangePostInfo,
    post?.sponsoredPostInfo?.aliasUser,
  ])

  return (
    <ContentContainer>
      <Helmet>
        <title>
          {post?.sponsoredPostInfo?.adName ?? 'Untitled'} - sportsYou Sponsored
          Posts
        </title>
      </Helmet>
      <Sticky bottomBoundary='#footeractions' innerZ={1}>
        <Header>
          <HeaderTitle>
            <Link to={'/sponsored-posts'} title='Sponsored Posts Home'>
              <Icon name='Home' fill={Colors.BLACK} width={18} />
            </Link>
            <span>
              <HeaderTitleChevron />
              {isNew && 'Create'}
              {!isNew && (
                <>
                  <StyledLink to={`/sponsored-posts/${post?.id}`}>
                    {post?.sponsoredPostInfo?.adName ?? 'Untitled'}
                  </StyledLink>
                  <HeaderTitleChevron />
                  Edit
                </>
              )}
            </span>
            {!!isLoading && <Spinner size={20} />}
          </HeaderTitle>
          {isEditable && (
            <PostActions>
              <CancelButton
                onClick={onClickCancelButton}
                to={isNew ? '/sponsored-posts' : `/sponsored-posts/${post?.id}`}
              >
                Cancel
              </CancelButton>
              <Button
                disabled={isSaving}
                loading={isSaving}
                onClick={onClickSave}
              >
                {isNew ? 'Save and Close' : 'Save and Close'}
              </Button>
            </PostActions>
          )}
        </Header>
      </Sticky>
      <Columns>
        <LeftColumn>
          {isNew ? (
            <H1>Create a Sponsored Post</H1>
          ) : (
            <H1>Edit Sponsored Post</H1>
          )}
          <TextInputContainer>
            <TextInputStyled
              label='Sponsored Post Title'
              type='text'
              placeholder='My Sponsored Post'
              value={post?.sponsoredPostInfo?.adName ?? ''}
              onChange={(e) =>
                onChangeSponsoredPostInfo('adName', e.target.value)
              }
              autoFocus={isNew}
              containerStyle={{ width: '100%' }}
            />
          </TextInputContainer>
          <H1>Post Content</H1>
          <TextInputContainer>
            <TextAreaStyled
              autoGrow
              containerStyle={{ width: '100%' }}
              disabled={!isEditable}
              label='Message'
              onChange={(e) => onChangePostInfo('message', e.target.value)}
              placeholder="Example: What's going on?"
              value={post?.message ?? ''}
            />
          </TextInputContainer>
          <TextInputContainer>
            <InputContainer>
              <InputContainerLabel>Post Type</InputContainerLabel>
              <Select
                isMulti={false}
                onChange={onPostTypeChange}
                options={postTypeOptions}
                placeholder='Post Type'
                value={postTypeOptions[postType]}
                isDisabled={!isNew}
              />
            </InputContainer>
          </TextInputContainer>

          {/* Media post */}
          {postType === PostType.Media ? (
            <>
              {isNew ? (
                // Media (drag and drop)
                <TextInputContainer>
                  <InputContainer
                    {...getRootProps({
                      className: 'dropzone',
                      role: 'button',
                    })}
                  >
                    <InputContainerLabel>Media</InputContainerLabel>
                    <input {...getInputProps()} />
                    {!post?.uploads?.length &&
                      !videoUploadsInProgress?.length && (
                        <InputContainerEmpty>
                          {isDragActive
                            ? 'Drop the files here ...'
                            : 'Drag and drop your file here, or click to select'}
                        </InputContainerEmpty>
                      )}
                    {!!videoUploadsInProgress?.length && (
                      <UploadThumbs>
                        {videoUploadsInProgress?.map((upload) => (
                          <UploadThumbContainer key={upload.id}>
                            <Spinner fill={Colors.WHITE} />
                          </UploadThumbContainer>
                        ))}
                      </UploadThumbs>
                    )}
                    {!!post?.uploads?.length && (
                      <UploadThumbs>
                        {post?.uploads?.map((upload, index) => {
                          if (isFile(upload?.contentType as string)) {
                            return (
                              <UploadThumbContainerFile key={upload?.id}>
                                <ThumbX
                                  onClick={(e) =>
                                    onRemoveUpload(e, index, upload!)
                                  }
                                />
                                <FileTypeIcon
                                  contentType={upload?.contentType as string}
                                  fileName={upload?.fileName as string}
                                  nameCase='pascal'
                                  size={70}
                                />
                              </UploadThumbContainerFile>
                            )
                          }
                          const thumbSrc = getTranscodeUrl({
                            upload:
                              (upload?.transcodes as ApiImageProp[]) ?? [],
                            transcodeTypes: [
                              'THUMB',
                              'feedLarge',
                              'feed',
                              'media',
                            ],
                          })
                          const previewDataUrl = upload?.viewUrl?.startsWith(
                            'blob'
                          )
                            ? upload?.viewUrl
                            : undefined
                          const src = thumbSrc || previewDataUrl
                          return (
                            <UploadThumbContainer key={upload?.id}>
                              <ThumbX
                                onClick={(e) =>
                                  onRemoveUpload(e, index, upload!)
                                }
                              />
                              {!thumbSrc && !previewDataUrl && (
                                <Spinner fill={Colors.WHITE} />
                              )}
                              {!!src && (
                                <>
                                  <UploadThumb key={index} src={src} />
                                  {!thumbSrc && (
                                    <SpinnerContainer>
                                      <Spinner fill={Colors.WHITE} />
                                    </SpinnerContainer>
                                  )}
                                </>
                              )}
                              {!!thumbSrc &&
                                isVideo(upload?.contentType ?? '') && (
                                  <ThumbEdit
                                    onClick={(e) =>
                                      onVideoEdit(e, index, upload!)
                                    }
                                  />
                                )}
                            </UploadThumbContainer>
                          )
                        })}
                      </UploadThumbs>
                    )}
                    <Button variant='alternate'>Select Image/Video/File</Button>
                  </InputContainer>
                </TextInputContainer>
              ) : post?.uploads?.length ? (
                // Media (edit)
                <TextInputContainer>
                  <InputContainerLabel>Media</InputContainerLabel>
                  <input {...getInputProps()} />
                  {!!post?.uploads?.length && (
                    <UploadThumbs>
                      {post?.uploads?.map((upload, index) => {
                        const thumbSrc = upload?.transcodes
                          ?.filter((tr) =>
                            ['THUMB', 'feed'].includes(tr?.transcodeType ?? '')
                          )
                          .shift()?.viewUrl
                        return (
                          <UploadThumbContainer key={upload?.id}>
                            {!thumbSrc && <Spinner fill={Colors.WHITE} />}
                            {!!thumbSrc && (
                              <UploadThumb key={index} src={thumbSrc} />
                            )}
                            {thumbSrc && isVideo(upload?.contentType ?? '') && (
                              <ThumbEdit
                                onClick={(e) => onVideoEdit(e, index, upload!)}
                              />
                            )}
                          </UploadThumbContainer>
                        )
                      })}
                    </UploadThumbs>
                  )}
                </TextInputContainer>
              ) : null}

              {/* Media click through action */}
              <TextInputContainer>
                <InputContainer>
                  <InputContainerLabel>
                    Media click-through action
                  </InputContainerLabel>
                  <label>
                    <input
                      type={'checkbox'}
                      checked={
                        post?.sponsoredPostInfo?.openWebLinkForImages === false
                      }
                      onChange={(e) => {
                        onChangeSponsoredPostInfo('openWebLinkForImages', false)
                      }}
                      value={'false'}
                      disabled={!isEditable}
                    />{' '}
                    Show media in fullscreen
                  </label>
                  <br />
                  <label>
                    <input
                      type={'checkbox'}
                      checked={
                        post?.sponsoredPostInfo?.openWebLinkForImages !== false
                      }
                      onChange={(e) => {
                        onChangeSponsoredPostInfo(
                          'openWebLinkForImages',
                          e.target.value === 'true'
                        )
                      }}
                      value={'true'}
                      disabled={!isEditable}
                    />{' '}
                    Navigate to Click-through URL in user's web browser
                  </label>
                </InputContainer>
              </TextInputContainer>
            </>
          ) : null}

          {/* Color Post */}
          {postType === PostType.Color ? (
            isNew ? (
              <TextInputContainer>
                <InputContainer>
                  <InputContainerLabel>Post Color</InputContainerLabel>
                  <input
                    onChange={(e) => onChangePostInfo('color', e.target.value)}
                    type='color'
                    value={post?.color ?? '#000'}
                  />{' '}
                </InputContainer>
              </TextInputContainer>
            ) : null
          ) : null}

          <TextInputContainer>
            <InputContainer>
              <InputContainerLabel>Disable Comments</InputContainerLabel>
              <label>
                <input
                  type={'checkbox'}
                  checked={!allowComments}
                  onChange={() => setAllowComments((prev) => !prev)}
                  disabled={!isEditable}
                />{' '}
                Disable comments
              </label>
            </InputContainer>
          </TextInputContainer>
          <TextInputContainer>
            <InputContainer>
              <InputContainerLabel>Position in Feed</InputContainerLabel>
              <label>
                <input
                  type={'checkbox'}
                  checked={
                    post?.sponsoredPostInfo?.feedPosition === 'float-first'
                  }
                  onChange={(e) => {
                    onChangeSponsoredPostInfo('feedPosition', 'float-first')
                  }}
                  value={'float-first'}
                  disabled={!isEditable}
                />{' '}
                Start as first item and float down as new posts come in
              </label>
              <br />
              <label>
                <input
                  type={'checkbox'}
                  checked={
                    post?.sponsoredPostInfo?.feedPosition === 'float-second'
                  }
                  onChange={(e) => {
                    onChangeSponsoredPostInfo('feedPosition', 'float-second')
                  }}
                  value={'float-second'}
                  disabled={!isEditable}
                />{' '}
                Start as second item and float down as new posts come in
              </label>
              <br />
              <label>
                <input
                  type={'checkbox'}
                  checked={
                    post?.sponsoredPostInfo?.feedPosition === 'pinned-first'
                  }
                  onChange={(e) => {
                    onChangeSponsoredPostInfo('feedPosition', 'pinned-first')
                  }}
                  value={'pinned-first'}
                  disabled={!isEditable}
                />{' '}
                Pinned as first item on feed
              </label>
              <br />
              <label>
                <input
                  type={'checkbox'}
                  checked={
                    post?.sponsoredPostInfo?.feedPosition === 'pinned-second'
                  }
                  onChange={(e) => {
                    onChangeSponsoredPostInfo('feedPosition', 'pinned-second')
                  }}
                  value={'pinned-second'}
                  disabled={!isEditable}
                />{' '}
                Pinned as second item on feed
              </label>
            </InputContainer>
          </TextInputContainer>
          <TextInputContainer>
            <InputContainer>
              <InputContainerLabel>View Only Once</InputContainerLabel>
              <label>
                <input
                  type={'checkbox'}
                  onChange={(e) => {
                    onChangeSponsoredPostInfo('viewOnlyOnce', e.target.checked)
                  }}
                  checked={post?.sponsoredPostInfo?.viewOnlyOnce ?? false}
                  value={
                    post?.sponsoredPostInfo?.viewOnlyOnce ? 'true' : 'false'
                  }
                  disabled={!isEditable}
                />{' '}
                Hide post from feed after viewed by each user
              </label>
            </InputContainer>
          </TextInputContainer>
          <TextInputContainer>
            <TextInputStyled
              label='Click-through URL'
              type='text'
              placeholder='Example: https://www.example.com/my-landing-page'
              value={post?.sponsoredPostInfo?.clickThroughUrl ?? ''}
              onChange={(e) =>
                onChangeSponsoredPostInfo('clickThroughUrl', e.target.value)
              }
              containerStyle={{ width: '100%' }}
              disabled={!isEditable}
            />
          </TextInputContainer>
          <TextInputContainer>
            <TextInputStyled
              label='Click-through Destination Description'
              type='text'
              placeholder='Example: example.com'
              value={post?.sponsoredPostInfo?.clickThroughText ?? ''}
              onChange={(e) =>
                onChangeSponsoredPostInfo('clickThroughText', e.target.value)
              }
              containerStyle={{ width: '100%' }}
              disabled={!isEditable}
            />
          </TextInputContainer>
          <TextInputContainer>
            <TextInputStyled
              label='Call to action Headline'
              type='text'
              placeholder='Example: Your headline'
              value={post?.sponsoredPostInfo?.headline ?? ''}
              onChange={(e) =>
                onChangeSponsoredPostInfo('headline', e.target.value)
              }
              containerStyle={{ width: '100%' }}
              disabled={!isEditable}
            />
          </TextInputContainer>
          <TextInputContainer>
            <TextInputStyled
              label='Call to action Tagline'
              type='text'
              placeholder='Example: More info'
              value={post?.sponsoredPostInfo?.tagline ?? ''}
              onChange={(e) =>
                onChangeSponsoredPostInfo('tagline', e.target.value)
              }
              containerStyle={{ width: '100%' }}
              disabled={!isEditable}
            />
          </TextInputContainer>
          <TextInputContainer>
            <TextInputStyled
              label='Call to action Button Text'
              type='text'
              placeholder='Example: Learn More'
              value={post?.sponsoredPostInfo?.buttonText ?? ''}
              onChange={(e) =>
                onChangeSponsoredPostInfo('buttonText', e.target.value)
              }
              containerStyle={{ width: '100%' }}
              disabled={!isEditable}
            />
          </TextInputContainer>
          <TextInputContainer>
            <span className='multi-select__label'>Priority</span>
            <Select
              classNamePrefix='multi-select'
              isMulti={false}
              onChange={(value: any) => setPriorities([value])}
              options={priorityOptions}
              placeholder='Priority'
              value={priorities}
              isDisabled={!isEditable}
            />
          </TextInputContainer>
          <TextInputContainer>
            <DateRange
              disabled={!isEditable}
              endDate={endDate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              showTimeSelect
              startDate={startDate}
            />
          </TextInputContainer>
          <TextInputContainer>
            <TextInputStyled
              label='Community Id for Follow Button'
              type='text'
              placeholder='Example Community ID: co-12345-12345'
              value={post?.sponsoredPostInfo?.communityId ?? ''}
              onChange={(e) =>
                onChangeSponsoredPostInfo('communityId', e.target.value)
              }
              containerStyle={{ width: '100%' }}
              disabled={!isEditable}
            />
          </TextInputContainer>
          {canUpdateAliasUser && isNew && (
            <TextInputContainer>
              <TextInputStyled
                containerStyle={{ width: '100%' }}
                label='Posting As'
                onChange={(e) =>
                  onChangeSponsoredPostInfo('aliasUser', {
                    id: e.target.value,
                  })
                }
                placeholder='Example User ID: us-12345-12345'
                type='text'
                value={''}
              />
            </TextInputContainer>
          )}
          <H1>Audience</H1>
          <TextInputContainer>
            <span className='multi-select__label'>User Types</span>
            <Select
              classNamePrefix='multi-select'
              isMulti
              onChange={(value: any) => setAudienceUserTypes(value)}
              options={audienceUserTypesOptions}
              placeholder='All User Types'
              value={audienceUserTypes}
              isDisabled={!isEditable}
            />
          </TextInputContainer>
          <TextInputContainer>
            <span className='multi-select__label'>Genders</span>
            <Select
              classNamePrefix='multi-select'
              isMulti
              onChange={(value: any) => setAudienceGenders(value)}
              options={audienceGendersOptions}
              placeholder='All Genders'
              value={audienceGenders}
              isDisabled={!isEditable}
            />
          </TextInputContainer>
          <TextInputContainer>
            <span className='multi-select__label'>Roles</span>
            <Select
              classNamePrefix='multi-select'
              isMulti
              onChange={(value: any) => setAudienceRoles(value)}
              options={audienceRolesOptions}
              placeholder='All Roles'
              value={audienceRoles}
              isDisabled={!isEditable}
            />
          </TextInputContainer>
          <TextInputContainer>
            <span className='multi-select__label'>Sports</span>
            <Select
              classNamePrefix='multi-select'
              isMulti
              onChange={(value: any) => setAudienceSports(value)}
              options={audienceSportsOptions}
              placeholder='All Sports'
              value={audienceSports}
              isDisabled={!isEditable}
            />
          </TextInputContainer>
          <TextInputContainer>
            <span className='multi-select__label'>Metro Areas</span>
            <Select
              classNamePrefix='multi-select'
              isMulti
              onChange={(value: any) => setAudienceMetroAreas(value)}
              options={audienceMetroAreaOptions}
              placeholder='All Metro Areas'
              value={audienceMetroAreas}
              isDisabled={!isEditable}
            />
          </TextInputContainer>
          <TextInputContainer>
            <InputContainer>
              <InputContainerLabel>Locations</InputContainerLabel>

              <LocationList>
                {!post?.sponsoredPostInfo?.selections?.locations?.length && (
                  <LocationListPlaceholder>Everywhere</LocationListPlaceholder>
                )}
                {post?.sponsoredPostInfo?.selections?.locations?.map(
                  (location, index) => (
                    <LocationPickerItem
                      index={index}
                      key={index}
                      location={location}
                      onChange={onChangeLocationPickerItem}
                      onRemove={onRemoveLocationPickerItem}
                    />
                  )
                )}
              </LocationList>
              {isEditable && (
                <AddLocationButton
                  onClick={onAddAnotherLocation}
                  variant='alternate'
                >
                  <Plus height={10} /> Set a specific Location
                </AddLocationButton>
              )}
            </InputContainer>
          </TextInputContainer>
          <TextInputContainer>
            <AgeRangeContainer>
              <TextInputStyled
                containerStyle={{ width: '100px' }}
                label='Min Age'
                maxLength={2}
                onChange={(e) => onChangeAudience('minAge', e.target.value)}
                placeholder='13'
                type='text'
                value={post?.sponsoredPostInfo?.selections?.minAge?.toString()}
                disabled={!isEditable}
              />
              –
              <TextInputStyled
                containerStyle={{ width: '100px' }}
                label='Max Age'
                maxLength={2}
                onChange={(e) => onChangeAudience('maxAge', e.target.value)}
                placeholder='99'
                type='text'
                value={post?.sponsoredPostInfo?.selections?.maxAge?.toString()}
                disabled={!isEditable}
              />
            </AgeRangeContainer>
          </TextInputContainer>
          {(canCreatePost || isEditable) && (
            <TextInputContainer>
              <hr />

              <h3>
                Estimated Reach: {reach !== undefined ? `~${reach}` : '--'}
              </h3>
              <Button
                disabled={isLoadingReach}
                loading={isLoadingReach}
                loadingText='Checking...'
                onClick={handleOnClickRefreshReach}
              >
                Check Audience Reach
              </Button>
            </TextInputContainer>
          )}
        </LeftColumn>
        <RightColumn>
          <Sticky bottomBoundary='#footeractions' top={75}>
            <PostContainer>
              <H1>Preview</H1>
              <PostWrapper>
                <PostComponentStyled
                  {...post}
                  metadata={metadata}
                  allowComments={allowComments}
                  community={
                    post?.sponsoredPostInfo?.communityId
                      ? {
                          ...post?.community,
                          id: post?.sponsoredPostInfo?.communityId,
                        }
                      : undefined
                  }
                  onClickUpload={onClickUpload}
                />
              </PostWrapper>
            </PostContainer>
          </Sticky>
        </RightColumn>
      </Columns>
      <TableTools id='footeractions'>
        <TableToolsLeft>
          {!isNew && !post?.deletedAt && post?.canDelete && (
            <>
              <hr />
              <Button variant={'danger'} onClick={onClickDelete}>
                Deactivate This Post
              </Button>
            </>
          )}
        </TableToolsLeft>
        <TableToolsRight></TableToolsRight>
      </TableTools>
      {!!isLoading && <Spinner />}
      <VideoThumbnailUpdateModal
        isOpen={openVideoThumbnailUpdateModal}
        isPostMedia={!isNew}
        onClose={onCloseVideoThumbnailUpdateModal}
        videoFile={videoFile as ExtendedUpload}
      />
    </ContentContainer>
  )
}

const H1 = styled.h1`
  margin-bottom: 10px;
  font-size: 20px;
`

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const LeftColumn = styled.div`
  width: 40%;
  margin-top: 1rem;
`

const RightColumn = styled.div`
  width: 60%;
  margin-left: 1rem;
  margin-top: 1rem;
`

const PostContainer = styled.div`
  align-items: flex-start;
  background-color: ${Colors.ALTO};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0rem 2rem 2rem 2rem;
  min-height: 100vh;
  border-radius: 8px;
`
const PostWrapper = styled.div`
  /* max-width: 400px;
  min-width: 400px; */
  width: 100%;
`

const PostComponentStyled = styled(PostComponent)`
  /* width: 400px; */
`

const TextInputContainer = styled.div`
  margin-bottom: 1rem;
`

const TextInputStyled = styled(TextInput)`
  width: 650px;
`

const TextAreaStyled = styled(TextArea)`
  width: 650px;
`

const AgeRangeContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1rem;
`

const InputContainer = styled.div`
  border: 1px solid #dddddd;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 10px;
`

const InputContainerLabel = styled.div`
  align-items: center;
  color: ${Colors.PUNCH};
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  text-transform: uppercase;
`

const InputContainerEmpty = styled.div`
  border-radius: 8px;
  border: 1px dashed #dddddd;
  color: ${Colors.SHUTTLE_GRAY};
  cursor: pointer;
  margin: 10px 0;
  padding: 10px;
`

const UploadThumbs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1rem;
  width: 650px;
`

const UploadThumbContainer = styled.div`
  align-items: center;
  background-color: ${Colors.MINE_SHAFT};
  border-radius: 8px;
  border: 1px solid ${Colors.ALTO};
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100px;
`
const UploadThumbContainerFile = styled(UploadThumbContainer)`
  background-color: ${Colors.WHITE};
`

const ThumbX = styled(X)`
  background-color: ${Colors.WHITE};
  border-radius: 50%;
  border: 1px solid ${Colors.SHUTTLE_GRAY};
  color: ${Colors.SHUTTLE_GRAY};
  cursor: pointer;
  font-size: 20px;
  padding: 3px;
  position: absolute;
  right: 0;
  top: 0;
`

const ThumbEdit = styled(Pencil)`
  background-color: ${Colors.WHITE};
  border-radius: 25%;
  border: 1px solid ${Colors.SHUTTLE_GRAY};
  color: ${Colors.SHUTTLE_GRAY};
  cursor: pointer;
  font-size: 20px;
  padding: 3px;
  position: absolute;
  left: 0;
  top: 0;
`

const UploadThumb = styled.img`
  max-height: 100px;
  max-width: 100px;
`

const LocationList = styled.div``

const LocationListPlaceholder = styled.div`
  color: ${Colors.SHUTTLE_GRAY};
`

const AddLocationButton = styled(Button)`
  height: 25px;
  margin-bottom: 5px;
  min-height: 25px;
`

const PostActions = styled.div`
  align-items: center;
  display: flex;
`

const CancelButton = styled(Link)`
  margin-right: 40px;
  text-decoration: none;
  color: ${Colors.HAVELOCK_BLUE};
`

const StyledLink = styled(Link)`
  color: ${Colors.BLACK};
`

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`
