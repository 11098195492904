import GetMetadata from './get-metadata'
import GetQRCode from './get-qr-code'
import GetChatsHistory from './get-chats-history'
import GetUserInfo from './get-user-info'

export * from './get-metadata'
export * from './get-qr-code'
export * from './get-chats-history'
export * from './get-user-info'

export default {
  ...GetMetadata,
  ...GetQRCode,
  ...GetChatsHistory,
  ...GetUserInfo,
}
