import { Image } from '@sportsyou/api'

type TranscodeType = 'coverImage' | 'feed' | 'feedLarge' | 'profileImage'

/**
 * Get the `viewUrl` of an image by transcode type from an array of images
 * @param images Array of images
 * @param type Transcode type of image to get
 * @returns URL of image
 */
const getImageByTranscodeType = (
  images: Image[],
  type: TranscodeType
): string | undefined => {
  return images
    ? images.filter((image) => image.transcodeType === type)[0]?.viewUrl ??
        undefined
    : undefined
}

export default getImageByTranscodeType
