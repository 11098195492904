/**
 * PostContainer
 *
 * The PostContainer component is the main ui module for the post component. It the props
 * from post and passes them to their approprite post module.
 */

import React, {
  ChangeEvent,
  FC,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { Checkmark, X } from '@sportsyou/react-icons'
import { Colors } from '@sportsyou/core'
import {
  PostMetadata as PostMetadataProps,
  VideoPlaylistSummary,
} from '@sportsyou/api'

import { useOnScreen } from '@sportsyou/react-hooks'

import {
  PostContent,
  PostFooter,
  PostHeader,
  PostHelmet,
  PostMenuAction,
  PostSponsorship,
} from './modules'
import { PostMetadata } from './modules/metadata'
import PostCommunity from './modules/community'

import Button from '../button'
import Comment from '../comment'
import TextInput from '../text-input'
import VideoPlaylistCard from '../video-playlist-card'
import type { PostProps } from './post-types'
import { BORDER_RADIUS } from '../../theme'
import { isEqual } from 'lodash'

export interface PostContainerProps extends PostProps {
  autoFocusCommentInput?: boolean
  className?: string
  isFetchingMoreComments?: boolean
  /** Determines if the post is a multi-post */
  isMultiPost?: boolean
  isScheduledPost?: boolean
  /** Right drop down in post header */
  menuActions?: Array<PostMenuAction>
  /**
   * Whether or not the post should be in a minimized state. (for multi-post)
   */
  minimized?: boolean
  /**
   * Hide the toggle button for expanding a post in a multi-post
   */
  hideToggleButton?: boolean

  numberOfComments?: number
  numberOfLikes?: number
  onClickVideoPlaylistCard?: (playlist: VideoPlaylistSummary) => void
  /**
   * Index of post within multi-post
   * @default undefined
   */
  postsIndex?: number
}

export const PostContainer: FC<PostContainerProps> = ({
  // hasMoreComments,
  activeCommentId,
  allowComments,
  attachments,
  autoFocusCommentInput,
  className,
  color,
  comments: propsComments,
  community,
  communityPostInfo,
  createdAt,
  createdBy,
  currentUserId,
  disableCommentsModal,
  disableLikesModal,
  hideFollowButton,
  hideMenu,
  hideToggleButton = false,
  id,
  isCreator,
  isEdited,
  isFetchingCount,
  isFetchingMoreComments,
  isLiked,
  isMultiPost = false,
  isPending,
  isPinned,
  isScheduledPost,
  menuActions,
  message,
  metadata,
  minimized = false,
  namesWhoCommented,
  namesWhoLiked,
  newCommentSuccessful,
  numberOfComments,
  numberOfLikes,
  onClick,
  onClickApprove,
  onClickAvatar,
  onClickCommentAuthor,
  onClickCommentButton,
  onClickCommentCount,
  onClickCreateComment,
  onClickDeleteScheduledPost,
  onClickDownloadUpload,
  onClickEditScheduledPost,
  onClickFollow,
  onClickHelmetProfile,
  onClickLikeButton,
  onClickLikeCount,
  onClickMoreComments,
  onClickPollChoice,
  onClickPollVotes,
  onClickProfileName,
  onClickReject,
  onClickRemoveVote,
  onClickSaveUpload,
  onClickTeamProfile,
  onClickUpload,
  onClickUserProfile,
  onClickVideoPlaylistCard,
  onClickViewedBy,
  onClickViewUpload,
  onDeleteComment,
  onExpand,
  onHoverCommentCount,
  onHoverLikeCount,
  onReportComment,
  onViewed,
  origDeleted,
  origPostId,
  playlists,
  poll,
  posts,
  postsIndex,
  scheduledTime,
  scheduleId,
  showCommentListComponent,
  sortComments,
  sponsoredPostInfo,
  team,
  uploads,
  viewCount,
  viewed,
}: PostContainerProps) => {
  const [comments, setComments] = useState(propsComments)

  const [areCommentsVisible, setAreCommentsVisible] = useState<boolean>(
    !!showCommentListComponent
  )
  const [commentValue, setCommentValue] = useState<string>('')
  const [isCommentButtonBusy, setIsCommentButtonBusy] = useState(false)
  const [isMinimized, setIsMinimized] = useState<boolean>(minimized)

  const hasMoreComments = useMemo(() => {
    return comments && numberOfComments
      ? comments.length < numberOfComments
      : false
    // return comments.length < (numberOfComments ?? 0) ?? false
  }, [comments, numberOfComments])

  const commentInputRef = useRef<HTMLInputElement>(null)
  const ref = useRef<HTMLDivElement>(null)
  const isOnScreen = useOnScreen(ref)

  useEffect(() => {
    let _id = id
    if (postsIndex && posts && posts.length > 0) {
      _id = posts[postsIndex].id
    }
    if (isOnScreen && onViewed && _id) {
      onViewed(_id)
    }
  }, [isOnScreen])

  useEffect(() => {
    if (!showCommentListComponent) {
      setCommentValue('')
    }
  }, [comments, showCommentListComponent])

  useEffect(() => {
    if (typeof newCommentSuccessful !== 'undefined') {
      if (newCommentSuccessful) {
        setCommentValue('')
      }
    }
  }, [newCommentSuccessful])

  useEffect(() => {
    if (!isEqual(comments, propsComments)) {
      // merge comments state
      const _comments = [...(comments ?? []), ...(propsComments ?? [])]
      // remove duplicates
      const uniqueComments = _comments.filter(
        (comment, index, self) =>
          index === self.findIndex((t) => t?.id === comment?.id)
      )
      setComments(uniqueComments)
    }
  }, [propsComments])

  const sortedComments = useMemo(() => {
    const _comments =
      posts && postsIndex ? posts[postsIndex].comments ?? [] : comments ?? []

    let sorted = [..._comments]

    if (sortComments === 'descending') {
      sorted = sorted.sort((a, b) => {
        if (!a || !b) return 0
        return moment(a?.createdAt).isBefore(moment(b?.createdAt)) ? 1 : -1
      })
    } else if (sortComments === 'ascending') {
      sorted = sorted.sort((a, b) => {
        if (!a || !b) return 0
        return moment(a?.createdAt).isAfter(moment(b?.createdAt)) ? 1 : -1
      })
    }

    return !sortComments ? _comments : sorted
  }, [comments, posts, postsIndex, sortComments])

  const getHelmetTargetName = useMemo(() => {
    if (team && team.name) {
      return team.name
    }
    if (createdBy) {
      if (createdBy.fullName) {
        return createdBy.fullName
      } else if (createdBy.firstName && createdBy.lastName) {
        return `${createdBy.firstName} ${createdBy.lastName}`
      } else {
        return createdBy.firstName ?? 'SportsYou User'
      }
    }
    return 'SportsYou User'
  }, [createdBy, team])

  const canShowCommentSection = useMemo(
    () =>
      !isMinimized &&
      (areCommentsVisible || showCommentListComponent) &&
      allowComments,
    [isMinimized, areCommentsVisible, showCommentListComponent, allowComments]
  )

  const togglePostVisibility = () => {
    setIsMinimized(!isMinimized)
    if (isMinimized) {
      let _id = id
      if (postsIndex && posts && posts.length > 0) {
        _id = posts[postsIndex].id
      }
      onExpand?.(_id ?? undefined)
    }
  }

  const handleOnClickApprove = () => {
    onClickApprove?.(id!)
  }

  const handleOnClickReject = () => {
    onClickReject?.(id!)
  }

  const handleOnChangeCommentValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setCommentValue(value)
  }

  const handleOnClickCommentButton = (postsIndex?: number) => {
    // Focus new comment input if comment list are visible
    if (showCommentListComponent) {
      commentInputRef.current?.focus()
    } else {
      // display or hide comments
      setAreCommentsVisible(!areCommentsVisible)
    }
    // callback
    onClickCommentButton?.(postsIndex)
  }

  const handleOnClickCreateComment = async (
    postId: string,
    message: string,
    wasRequestSuccessful?: boolean
  ) => {
    setIsCommentButtonBusy(true)
    await onClickCreateComment?.(postId, message)
    setIsCommentButtonBusy(false)
    setCommentValue('')
  }

  const handleOnClickMoreComments = () => {
    onClickMoreComments?.(postsIndex)
  }

  const handleOnClickCommentAuthor = (id: string) => {
    onClickCommentAuthor && onClickCommentAuthor(id)
  }

  const handleOnDeleteComment = useCallback(
    async (postId: string, commentId: string) => {
      const status = await onDeleteComment?.(postId, commentId)
      if (status && status === 'ok') {
        setComments((_comments) => {
          return _comments?.filter((comment) => comment?.id !== commentId)
        })
      }
    },
    [onDeleteComment]
  )

  return (
    <Container
      className={className}
      onClick={onClick}
      isMultiPost={isMultiPost}
      ref={ref}
    >
      {isMultiPost && <MultiPostIndicator />}
      {isPending && (
        <PendingBanner>
          <span style={{ marginRight: 'auto' }}>
            This post is pending approval.
          </span>
          <PendingButton
            aria-label='Approve Post'
            data-status='success'
            onClick={handleOnClickApprove}
            title='Approve Post'
            type='button'
          >
            <Checkmark fill='inherit' />
          </PendingButton>
          <PendingButton
            aria-label='Delete Post'
            data-status='danger'
            onClick={handleOnClickReject}
            title='Delete Post'
            type='button'
          >
            <X fill='inherit' />
          </PendingButton>
        </PendingBanner>
      )}
      {(team || isMultiPost || isScheduledPost) && (
        <PostHelmet
          createdBy={createdBy}
          hideToggleButton={hideToggleButton}
          isMinimized={isMinimized}
          isMultiPost={isMultiPost}
          isPinned={isPinned}
          onClickHelmetProfile={onClickHelmetProfile}
          onToggleMultiPost={togglePostVisibility}
          onClickEditScheduledPost={onClickEditScheduledPost}
          onClickViewedBy={onClickViewedBy}
          posts={posts}
          postsIndex={postsIndex}
          // postType={isMultiPost ? 'multi' : (team?.type as 'team' | 'group')}
          scheduleId={scheduleId}
          scheduledTime={scheduledTime}
          targetName={getHelmetTargetName}
          team={team}
          viewed={viewed}
          viewCount={viewCount}
        />
      )}
      {!isMinimized && (
        <>
          <PostHeader
            community={community ?? undefined}
            createdAt={createdAt}
            createdBy={createdBy}
            hideMenu={hideMenu ?? isScheduledPost}
            isEdited={isEdited}
            isSponsoredPost={!!sponsoredPostInfo}
            menuActions={menuActions}
            onClickAvatar={onClickAvatar}
            onClickProfileName={onClickProfileName}
            sponsoredPostInfo={sponsoredPostInfo}
            // team={team}
          />
          <PostContent
            // attachments={attachments}
            color={color}
            community={community}
            communityPostInfo={communityPostInfo}
            id={id}
            isCreator={isCreator}
            message={message}
            onClickDownloadUpload={onClickDownloadUpload}
            onClickPollChoice={onClickPollChoice}
            onClickPollVotes={onClickPollVotes}
            onClickRemoveVote={onClickRemoveVote}
            onClickSaveUpload={onClickSaveUpload}
            onClickUpload={onClickUpload}
            onClickViewUpload={onClickViewUpload}
            origDeleted={origDeleted}
            origPostId={origPostId}
            poll={poll}
            sponsoredPostInfo={sponsoredPostInfo}
            uploads={uploads}
          />
          {playlists?.map((playlist: VideoPlaylistSummary | null, i: number) =>
            !playlist ? null : (
              <VideoPlaylistCard
                key={i}
                onClickVideoPlaylistCard={onClickVideoPlaylistCard}
                playlist={playlist}
              />
            )
          )}

          {metadata
            ?.filter((data: PostMetadataProps) => !!data.displayMetaData)
            .map((data: PostMetadataProps, i: number) => (
              <PostMetadata key={i} {...data} />
            ))}

          {!community && sponsoredPostInfo && (
            <PostSponsorship {...sponsoredPostInfo} />
          )}
          {community && !hideFollowButton && (
            <PostCommunity
              buttonText={sponsoredPostInfo?.buttonText!}
              clickThroughText={sponsoredPostInfo?.clickThroughText!}
              clickThroughUrl={sponsoredPostInfo?.clickThroughUrl!}
              community={community}
              headline={sponsoredPostInfo?.headline!}
              hideFollowButton={hideFollowButton}
              onClickFollow={onClickFollow}
              postId={id!}
              tagline={sponsoredPostInfo?.tagline!}
            />
          )}
          <PostFooter
            allowComments={allowComments}
            comments={comments}
            disableCommentsModal={disableCommentsModal}
            disableLikesModal={disableLikesModal}
            id={id}
            isColorPost={!!color}
            isFetchingCount={isFetchingCount}
            isLiked={isLiked}
            namesWhoCommented={namesWhoCommented}
            namesWhoLiked={namesWhoLiked}
            numberOfComments={numberOfComments}
            numberOfLikes={numberOfLikes}
            onClickCommentButton={handleOnClickCommentButton}
            onClickCommentCount={onClickCommentCount}
            onClickDeleteScheduledPost={onClickDeleteScheduledPost}
            onClickLikeButton={onClickLikeButton}
            onClickLikeCount={onClickLikeCount}
            onHoverCommentCount={onHoverCommentCount}
            onHoverLikeCount={onHoverLikeCount}
            postsIndex={postsIndex}
            scheduleId={scheduleId}
            scheduledTime={scheduledTime}
            showComments={showCommentListComponent}
            showCommentTooltip={!!allowComments}
            showLikeTooltip={
              !(team?.isLargeTeam || sponsoredPostInfo || community)
            }
          />
        </>
      )}
      {canShowCommentSection ? (
        <CommentSection>
          {sortedComments.map((comment, i) => (
            <StyledComment
              {...comment}
              canEdit={team?.canEdit ?? false}
              currentUserId={currentUserId}
              isActive={activeCommentId === comment?.id}
              key={`comment-${i}`}
              onClickAuthor={(id) => handleOnClickCommentAuthor(id)}
              onDeleteComment={handleOnDeleteComment}
              onReportComment={onReportComment}
              postId={posts && postsIndex ? posts[postsIndex].id! : id!}
            />
          ))}

          {hasMoreComments && (
            <LoadMoreCommentsContainer>
              <Button
                appearance='ghost'
                loading={isFetchingMoreComments}
                onClick={handleOnClickMoreComments}
              >
                Load more comments
              </Button>
            </LoadMoreCommentsContainer>
          )}

          {/* TODO: Make component to use with other feeds */}
          {allowComments ? (
            <NewCommentContainer>
              <CommentInput
                autoFocus={autoFocusCommentInput}
                onChange={handleOnChangeCommentValue}
                placeholder='Add a comment...'
                ref={commentInputRef}
                value={commentValue}
              />
              <PostCommentButton
                // appearance='minimal'
                // collapse
                loading={isCommentButtonBusy}
                disabled={commentValue.trim() === '' || isCommentButtonBusy}
                onClick={() => handleOnClickCreateComment(id!, commentValue)}
              >
                Post
              </PostCommentButton>
            </NewCommentContainer>
          ) : null}
        </CommentSection>
      ) : null}
    </Container>
  )
}

const Container = styled.div<{ isMultiPost: boolean }>`
  border: 1px solid ${Colors.ALTO};
  background-color: ${Colors.WHITE};
  border-radius: ${BORDER_RADIUS};
  box-sizing: border-box;
  // overflow: hidden;
  position: relative;
  width: 100%;
  &:not(:first-child) {
    margin-top: ${({ isMultiPost }) => (isMultiPost ? '6px' : '10px')};
  }
`
// Visual line on the left side of a multi-post
const MultiPostIndicator = styled.div`
  background-color: ${Colors.HAVELOCK_BLUE};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 3px;
  z-index: 10;
`
const PendingBanner = styled.div`
  align-items: center;
  background-color: #fff6bf;
  border-radius: 4px;
  color: ${Colors.BLACK};
  display: flex;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
`
const PendingButton = styled.button`
  background: none;
  border: 2px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  width: 28px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 160ms ease-in-out, color 160ms ease-in-out,
    fill 160ms ease-in-out;

  & + & {
    margin-left: 10px;
  }

  &[data-status='success'] {
    border-color: ${Colors.MOUNTAIN_MEADOW};
    fill: ${Colors.MOUNTAIN_MEADOW};
  }
  &[data-status='danger'] {
    border-color: ${Colors.MONZA};
    fill: ${Colors.MONZA};
  }
  &:hover,
  &:active {
    background-color: rgb(255 255 255 / 18%);
    &[data-status='success'] {
      background-color: ${Colors.MOUNTAIN_MEADOW};
      fill: ${Colors.WHITE};
    }
    &[data-status='danger'] {
      background-color: ${Colors.MONZA};
      fill: ${Colors.WHITE};
    }
  }
`
const CommentSection = styled.div`
  border-top: 1px solid ${Colors.ALTO};
  margin-left: 10px;
  margin-right: 10px;
  // padding-bottom: 10px;
  // padding-top: 10px;
`
const StyledComment = styled(Comment)`
  padding: 10px;
  margin-left: -10px;
  margin-right: -10px;
`
const LoadMoreCommentsContainer = styled.div`
  border-top: 1px solid ${Colors.ALTO};
  // margin-top: 10px;
  padding-left: 10px;
  padding-top: 20px;
`
const NewCommentContainer = styled.div`
  align-items: flex-start;
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
`
const CommentInput = styled(TextInput)`
  flex: 1 1 auto;
  margin-right: 10px;
  min-height: 1px;
  min-width: 1px;
`
const PostCommentButton = styled(Button)`
  flex: 0 0 auto;
`

export default PostContainer
