import { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import {
  Button,
  CopyToClipboard,
  DateRange,
  FadeInContainer,
  Post as PostComponent,
  Select,
  Skeleton,
  Spinner,
  Table,
  Well,
} from '@sportsyou/react-dom-ui'
import {
  Community,
  CommunityPostStats,
  Post,
  queryCommunityPostStats,
  queryPost,
  queryUser,
  User,
} from '@sportsyou/api'
import {
  exportCSV,
  arrayToCVS,
  Colors,
  readableNumber,
  secondsToTime,
} from '@sportsyou/core'
import { useFetchApi } from '@sportsyou/react-hooks'

import ActivityDirectionIcon from '../../Campaigns/Detail/Metrics/ActivityDirectionIcon'
import ActivityGraph from '../../Campaigns/Detail/Metrics/ActivityGraph'

interface PagesPostMetricsProps {
  page: Community
  postId: string
}

const graphColors = [Colors.PUNCH, Colors.DUSTY_GRAY]
const graphKeys = ['views', 'clicks']

export default function PagesPostMetrics(props: PagesPostMetricsProps) {
  const [currentUser, setCurrentUser] = useState<User>()
  const [endDate, setEndDate] = useState(new Date())
  const [isLoading, setIsLoading] = useState(true)
  const [post, setPost] = useState<Post>()
  const [startDate, setStartDate] = useState(new Date())
  const [stats, setStats] = useState<CommunityPostStats[]>([])
  const [teamRole, setTeamRole] = useState<string | undefined>()
  const [totalStat, setTotalStat] = useState<CommunityPostStats>()

  const { fetch: getPagesPostStats } = useFetchApi(queryCommunityPostStats)
  const { fetch: getPagesPostDetails } = useFetchApi(queryPost)
  const { fetch: loadUser } = useFetchApi(queryUser)

  const videoDuration = useMemo(() => {
    return (
      post?.uploads?.find((upload) => (upload?.durationInMs ?? 0) > 0)
        ?.durationInMs ?? 0
    )
  }, [post])

  const hasVideo = useMemo(() => {
    return !!videoDuration
  }, [videoDuration])

  const pagesPostsDataColumns = useMemo(() => {
    const columns: any[] = [
      {
        name: 'Date',
        selector: (row: CommunityPostStats) => row?.label ?? '',
        sortable: true,
        reorder: true,
      },
      {
        name: 'Views',
        selector: (row: CommunityPostStats) => row?.postViewedCount ?? 0,
        sortable: true,
        reorder: true,
      },
      {
        name: 'Likes',
        selector: (row: CommunityPostStats) => row?.likeCount ?? 0,
        sortable: true,
        reorder: true,
      },
      // {
      //   name: 'Comments',
      //   selector: (row: CommunityPostStats) => row?.commentCount ?? 0,
      //   sortable: true,
      //   reorder: true,
      // },
    ]

    if (hasVideo) {
      const videoColumns = [
        {
          name: 'Video Views (Any Time)',
          selector: (row: CommunityPostStats) => row?.videoViewsCount ?? 0,
          sortable: true,
          reorder: true,
        },
        {
          name: 'Video Views (> Min Time)',
          selector: (row: CommunityPostStats) =>
            row?.videoNumberOfViewsOverMinViewedTime ?? 0,
        },
        {
          name: 'Total Video Time Viewed',
          selector: (row: CommunityPostStats) =>
            secondsToTime(row?.videoTotalViewedTime ?? 0),
          cell: (row: CommunityPostStats) =>
            secondsToTime(row?.videoTotalViewedTime ?? 0),
        },
        {
          name: 'Avg Video Time Viewed',
          selector: (row: CommunityPostStats) =>
            secondsToTime(row?.videoAverageViewedTime ?? 0),
          cell: (row: CommunityPostStats) =>
            `${secondsToTime(row?.videoAverageViewedTime ?? 0)} - ${
              videoDuration > 0
                ? Math.round(
                    (((row?.videoAverageViewedTime ?? 0) * 1000) /
                      videoDuration) *
                      100
                  )
                : 0
            }%`,
        },
      ]
      columns.push(...videoColumns)
    }
    return columns
  }, [hasVideo, videoDuration])

  const generateCSVData = useCallback(() => {
    return (
      stats?.map((stat) => {
        const stats = {
          Date: stat.label,
          Views: stat.postViewedCount ?? 0,
          Likes: stat.likeCount ?? 0,
          Comments: stat.commentCount ?? 0,
        }
        if (hasVideo) {
          const videoStats = {
            'Video Views (any time)': stat.videoViewsCount ?? 0,
            'Video Views (> min time)':
              stat.videoNumberOfViewsOverMinViewedTime ?? 0,
            'Total Video Time Viewed': stat.videoTotalViewedTime ?? 0,
            'Avg Video Time Viewed': stat.videoAverageViewedTime ?? 0,
          }
          return { ...stats, ...videoStats }
        }
        return stats
      }) ?? []
    )
  }, [hasVideo, stats])

  const copyCSVText = useCallback(() => {
    return arrayToCVS(generateCSVData())
  }, [generateCSVData])

  const onClickDownloadCSV = useCallback(() => {
    const fileName = post?.message?.slice(0, 20).replace(/\W/g, '-') ?? 'post'
    const date = moment().format('YYYY-MM-DD')
    exportCSV(generateCSVData(), `${fileName}_${date}.csv`)
  }, [generateCSVData, post?.message])

  const fetchStats = useCallback(async () => {
    setIsLoading(true)
    const { data: _stats } = await getPagesPostStats({
      postId: props.postId,
      endDate: endDate?.toISOString(),
      startDate: startDate?.toISOString(),
      teamRole: teamRole,
    })

    if (_stats) {
      const totalStatIndex = _stats.findIndex((stat) => stat.label === 'Total')
      if (totalStatIndex > -1) {
        const totalStat = _stats.splice(totalStatIndex, 1)
        setTotalStat(totalStat[0])
      }
      setStats(_stats)
    }

    setIsLoading(false)
  }, [getPagesPostStats, props.postId, endDate, startDate, teamRole])

  const viewsToday = useMemo(() => {
    if (!stats?.length) {
      return undefined
    }
    const todayStats = stats?.find((value) => {
      return moment(value?.label).isSame(moment(), 'day')
    })
    return todayStats?.postViewedCount ?? 0
  }, [stats])

  const clicksToday = useMemo(() => {
    if (!stats?.length) {
      return undefined
    }
    const todayStats = stats?.find((value) => {
      return moment(value?.label).isSame(moment(), 'day')
    })
    return todayStats?.clickThroughCount ?? 0
  }, [stats])

  const viewsYesterday = useMemo(() => {
    const yesterdayStats = stats?.find((value) => {
      return moment(value?.label).isSame(moment().subtract(1, 'day'), 'day')
    })
    return yesterdayStats?.postViewedCount ?? 0
  }, [stats])

  const clicksYesterday = useMemo(() => {
    const yesterdayStats = stats?.find((value) => {
      return moment(value?.label).isSame(moment().subtract(1, 'day'), 'day')
    })
    return yesterdayStats?.clickThroughCount ?? 0
  }, [stats])

  const activityGraphDataDaily = useMemo(() => {
    const activities = [...(stats ?? [])].sort((a, b) => {
      return moment(a?.label).diff(moment(b?.label))
    })
    // show only the most recent activities on the daily graph
    const daysToShow = 14
    const length =
      activities.length - daysToShow > 0 ? activities.length - daysToShow : 0
    return (
      activities.slice(length).map((value, index) => {
        return {
          name: moment(value?.label).format('MM/DD'),
          views: value?.postViewedCount as number,
          clicks: value?.clickThroughCount as number,
        }
      }) ?? []
    )
  }, [stats])

  const percentClicksToViews = useMemo(() => {
    const totalViews = totalStat?.postViewedCount ?? 0
    const totalClicks = totalStat?.clickThroughCount ?? 0
    if (totalViews > 0 && totalClicks > 0) {
      const percent = totalClicks / totalViews
      // percent to 2 decimal places, removing trailing zeros
      return ((percent ?? 0) * 100).toFixed(2).replace(/\.?0+$/, '')
    } else {
      return 0
    }
  }, [totalStat?.clickThroughCount, totalStat?.postViewedCount])

  useEffect(() => {
    const loadPost = async () => {
      const currentPost = await getPagesPostDetails({ id: props.postId })
      setPost(currentPost?.data)
      if (currentPost?.data?.approvedAt) {
        setStartDate(new Date(currentPost.data.approvedAt))
      }
      if (currentPost?.data?.deletedAt) {
        setEndDate(new Date(currentPost.data.deletedAt))
      }
    }
    loadPost()
  }, [getPagesPostDetails, props.postId])

  useEffect(() => {
    async function checkUser() {
      const res = await loadUser().catch((err) => console.log(err))
      if (!res?.data) return
      const userData: User = res?.data
      setCurrentUser(userData)
    }

    if (currentUser) {
      fetchStats()
    } else {
      checkUser()
    }
  }, [currentUser, loadUser, getPagesPostStats, fetchStats])

  return (
    <>
      <TableTools>
        <TableToolsItems>
          <TableOptions>
            <DateRange
              endDate={endDate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              startDate={startDate}
            />
            <StyledSelect initialValue='All Roles' label='Role'>
              <Select.Option
                isSelected={!teamRole}
                onClick={() => setTeamRole(undefined)}
                value='All Roles'
              >
                All Roles
              </Select.Option>
              <Select.Option
                isSelected={teamRole === 'player'}
                onClick={() => setTeamRole('player')}
                value='Players'
              >
                Players
              </Select.Option>
              <Select.Option
                isSelected={teamRole === 'coach'}
                onClick={() => setTeamRole('coach')}
                value='Coaches'
              >
                Coaches
              </Select.Option>
              <Select.Option
                isSelected={teamRole === 'parent'}
                onClick={() => setTeamRole('parent')}
                value='Family'
              >
                Family
              </Select.Option>
              <Select.Option
                isSelected={teamRole === 'other'}
                onClick={() => setTeamRole('other')}
                value='Group Members'
              >
                Group Members
              </Select.Option>
            </StyledSelect>
            <TableActions>
              <Button appearance='ghost' onClick={onClickDownloadCSV}>
                Download CSV
              </Button>
              <CopyToClipboard
                content={() => <Button appearance='ghost'>Copy CSV</Button>}
                contentOnCopy={() => (
                  <Button appearance='ghost'>Copied!</Button>
                )}
                text={copyCSVText}
              />
            </TableActions>
          </TableOptions>
        </TableToolsItems>
      </TableTools>

      <TotalsGraphAndPreview>
        <TotalsAndGraph>
          <SectionTitle>Total</SectionTitle>
          <TotalsLargeContainer>
            <TotalsLarge>
              <div>
                <label>Views</label>
                <TotalsLargeValue>
                  <Skeleton
                    barHeight={22}
                    width={40}
                    ready={totalStat !== undefined}
                  >
                    <FadeInContainer>
                      {readableNumber(totalStat?.postViewedCount as number)}
                    </FadeInContainer>
                  </Skeleton>
                </TotalsLargeValue>
              </div>
              <div>
                <label>Today</label>
                <Skeleton
                  barHeight={11}
                  width={35}
                  ready={viewsToday !== undefined}
                >
                  <FadeInContainer>
                    <TotalContainer>
                      <ActivityDirectionIcon
                        currentValue={viewsToday}
                        previousValue={viewsYesterday}
                      />
                      {readableNumber(viewsToday)}
                    </TotalContainer>
                  </FadeInContainer>
                </Skeleton>
              </div>
            </TotalsLarge>

            <TotalsLarge>
              <div>
                <label>Clicks</label>
                <TotalsLargeValue>
                  <Skeleton
                    barHeight={22}
                    width={100}
                    ready={totalStat?.clickThroughCount !== undefined}
                  >
                    <FadeInContainer>
                      {readableNumber(totalStat?.clickThroughCount as number)}
                      <span>{percentClicksToViews}% of views</span>
                    </FadeInContainer>
                  </Skeleton>
                </TotalsLargeValue>
              </div>
              <div>
                <label>Today</label>
                <Skeleton
                  barHeight={11}
                  width={35}
                  ready={clicksToday !== undefined}
                >
                  <FadeInContainer>
                    <TotalContainer>
                      <ActivityDirectionIcon
                        currentValue={clicksToday}
                        previousValue={clicksYesterday}
                      />
                      {readableNumber(clicksToday)}
                    </TotalContainer>
                  </FadeInContainer>
                </Skeleton>
              </div>
            </TotalsLarge>
          </TotalsLargeContainer>

          {!isLoading && (
            <TotalsLargeContainer>
              <ActivityGraphContainer>
                <ActivityGraphHeader>
                  <SectionTitle>Daily Activity</SectionTitle>
                </ActivityGraphHeader>
                {activityGraphDataDaily.length === 0 ? (
                  <div>No data to display</div>
                ) : (
                  <ActivityGraph
                    colors={graphColors}
                    data={activityGraphDataDaily}
                    keys={graphKeys}
                  />
                )}
              </ActivityGraphContainer>
            </TotalsLargeContainer>
          )}
        </TotalsAndGraph>

        <PostPreviewContainer>
          <SectionTitle>Preview</SectionTitle>
          <PostComponent {...post} community={props.page} hideFollowButton />
        </PostPreviewContainer>
      </TotalsGraphAndPreview>

      <TableContainer>
        {!isLoading && (
          <FadeInContainer>
            <TableSectionTitle>All Activity</TableSectionTitle>
            <Table
              // onRowClicked={() => {}}
              // pointerOnHover
              // selectableRows
              columns={pagesPostsDataColumns}
              customStyles={customTableStyles}
              data={stats}
              dense
              striped
            />
            {totalStat ? (
              <Table
                columns={pagesPostsDataColumns}
                customStyles={customTableStyles}
                data={[totalStat]}
                dense
                noTableHead
              />
            ) : null}
          </FadeInContainer>
        )}
        {!!isLoading && <Spinner />}
      </TableContainer>
    </>
  )
}

const customTableStyles = {
  headCells: {
    style: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
  cells: {
    style: {},
  },
}

const TableTools = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`

const TableToolsItems = styled.div`
  width: 100%;
`

const TableOptions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
`

const TableActions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
  width: 100%;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`

const PostPreviewContainer = styled.div`
  max-height: 400px;
  overflow: auto;
  width: 420px;

  & > *:nth-child(2) {
    margin-top: 0px;
  }
`

const StyledSelect = styled(Select)`
  button {
    min-width: 200px;
  }
`

const ActivityGraphContainer = styled.div`
  align-items: center;
  border-radius: 6px;
  border: 1px solid ${Colors.ALTO};
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 250px;
  max-height: 800px;
  min-height: 250px;
  padding: 20px 20px;
  margin-top: 20px;
  width: '100%';
`
const ActivityGraphHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const TotalsLargeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  min-height: 130px;
`

const TotalsLarge = styled(Well)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  font-size: 24px;
  justify-content: space-around;

  label {
    font-size: 14px;
    margin-bottom: 10px;
  }
`

const TotalsLargeValue = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;

  span {
    color: ${Colors.MINE_SHAFT};
    font-size: 14px;
    margin-left: 10px;
  }
`

const TotalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 500;
  gap: 5px;
  line-height: normal;
`

const SectionTitle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 700;
  justify-content: space-between;
  margin-bottom: 5px;
`

const TableContainer = styled.div`
  flex: 1;
  width: 100%;
  margin-top: 40px;
`

const TableSectionTitle = styled(SectionTitle)`
  margin-left: 15px;
`

const TotalsGraphAndPreview = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
`

const TotalsAndGraph = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
