import {
  ApiRequestOptions,
  ApiResponse,
  CacheOptions,
  StaticApiRequest,
  post,
} from '../api-base'

export interface GetUserPIIRequest {
  timezone: {
    name: string
    value: string
  }
  userId: string
  requestOptions?: ApiRequestOptions
}

export interface GetUserPIIResponse extends ApiResponse {
  data?: {
    message: string
  }
  error?: any
}

export async function getUserPII(
  request: GetUserPIIRequest
): Promise<GetUserPIIResponse> {
  const req: StaticApiRequest = {
    action: 'getUserPII',
    userId: request.userId,
    timezone: request.timezone,
  }

  const reqString = JSON.stringify(req)
  const options = request?.requestOptions ?? {
    useCache: CacheOptions.NEVER,
  }

  try {
    const response = await post(req, options)
    return {
      data: response.data,
      error: response.error,
      ok: response.ok,
      requestString: reqString,
    }
  } catch (ex: any) {
    return { ok: false, error: ex, requestString: reqString }
  }
}

export default getUserPII
