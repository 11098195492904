import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { Helmet } from 'react-helmet-async'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'
import moment from 'moment'
import Select, { SingleValue } from 'react-select'
import Sticky from 'react-stickynode'
import styled, { css } from 'styled-components'

import {
  Colors,
  POST_COLORS,
  getTranscodeUrl,
  isFile,
  isVideo,
  lighten,
  sleep,
} from '@sportsyou/core'
import {
  BORDER_RADIUS,
  Button,
  DatePicker,
  FileTypeIcon,
  Icon,
  Post as PostComponent,
  Spinner,
  TextArea,
  TextInput,
  useDialog,
} from '@sportsyou/react-dom-ui'
import { useFetchApi, useUploader } from '@sportsyou/react-hooks'
import {
  ExtendedFile,
  ExtendedUpload,
  UploaderProps,
} from '@sportsyou/react-hooks'
import {
  Image as ApiImageProp,
  MutationPostCreateArgs,
  MutationPostUpdateArgs,
  MutationUploadUpdateVideoPosterImageRequest,
  Post as ApiPostProp,
  Upload,
  User,
  mutationPostCommentsEnable,
  mutationPostCreate,
  mutationPostDelete,
  mutationPostUpdate,
  mutationUploadDelete,
  mutationUploadUpdateVideoPosterImage,
  queryPost,
  queryUploadUrl,
  queryUser,
  Poll,
  PollInput,
} from '@sportsyou/api'

import {
  Header,
  HeaderTitle,
  TableTools,
  TableToolsLeft,
  TableToolsRight,
} from '../../SponsoredPosts'
import {
  ContentContainer,
  HeaderTitleChevron,
} from '../../../styles/global-styles'
import VideoThumbnailUpdateModal from '../../SponsoredPosts/components/VideoThumbnailUpdateModal'
import usePagesPortal from '../UsePagesPortal'

interface Option {
  label: string
  value: string
}

enum PostType {
  Media,
  Color,
  Poll,
}

const postTypeOptions: Option[] = [
  { label: 'Media Post', value: '0' },
  { label: 'Color Post', value: '1' },
  { label: 'Poll', value: '2' },
]

const initialPollExpDate: string = moment()
  .set('minutes', 0)
  .add(2, 'hours')
  .toDate()
  .toISOString()
const initialPollOptions: string[] = ['', '']

export default function CommunitiesPostEditor() {
  const { sendBanner, sendConfirm } = useDialog()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const uploader = useUploader.useUploader()

  const { page } = usePagesPortal({ communityId: params.id })

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: 50 * (1024 * 1024 * 1024), // 50GB
    onDropAccepted: onDropFileAccepted,
    onDropRejected: onDropFileRejected,
    useFsAccessApi: false,
  })

  const [currentUser, setCurrentUser] = useState<User>()
  const [isEditable, setIsEditable] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [isNew] = useState(() => !params.postId)
  const [isSaving, setIsSaving] = useState(false)
  const [pollOptions, setPollOptions] = useState<string[]>(initialPollOptions)
  const [pollExpDate, setPollExpDate] = useState<string>(initialPollExpDate)
  const [post, setPost] = useState<ApiPostProp | null>()
  const [postType, setPostType] = useState<PostType>(PostType.Media)

  const [videoUploadsInProgress, setVideoUploadsInProgress] =
    useState<ExtendedUpload[]>()
  const [openVideoThumbnailUpdateModal, setOpenVideoThumbnailUpdateModal] =
    useState(false)
  const [videoFile, setVideoFile] = useState<Upload | undefined>()
  const [allowComments, setAllowComments] = useState(false)

  const videosUpdated = useRef(new Map<string, Upload>())

  const { fetch: createPost } = useFetchApi(mutationPostCreate)
  const { fetch: deletePost } = useFetchApi(mutationPostDelete)
  const { fetch: getPost } = useFetchApi(queryPost)
  const { fetch: getUploadUrl } = useFetchApi(queryUploadUrl)
  const { fetch: getUser } = useFetchApi(queryUser)
  const { fetch: updatePost } = useFetchApi(mutationPostUpdate)
  const { fetch: updatePosterImage } = useFetchApi(
    mutationUploadUpdateVideoPosterImage
  )
  const { fetch: updateAllowComments } = useFetchApi(mutationPostCommentsEnable)
  const { fetch: removeMedia } = useFetchApi(mutationUploadDelete)

  const pathPortal = useMemo(() => `/pages`, [])
  const pathCommunity = useMemo(
    () => `${pathPortal}/${page.id}`,
    [page.id, pathPortal]
  )
  const pathPosts = useMemo(() => `${pathCommunity}/posts`, [pathCommunity])
  const pathPost = useMemo(
    () => `${pathPosts}/${post?.id}`,
    [pathPosts, post?.id]
  )

  const fetchData = useCallback(async () => {
    if (isNew) {
      setPost({
        message: '',
        allowComments: false,
        communityPostInfo: {
          openWebLinkForImages: false,
        },
        createdBy: {
          id: currentUser?.id,
          fullName: currentUser?.fullName,
          profileImage: currentUser?.profileImage,
        },
      })
      setIsEditable(true)
    } else {
      const { data } = await getPost({
        id: params.postId,
      })
      if (data) {
        setAllowComments(!!data?.allowComments)
        setPost(() => ({
          ...data,
          message: data?.poll?.question ?? data?.message,
        }))
        if (data.color) {
          setPostType(PostType.Color)
        } else if (data.poll) {
          setPostType(PostType.Poll)
        } else {
          setPostType(PostType.Media)
        }
        setIsEditable(data.canEdit || false)
      }
    }
    setIsLoading(false)
  }, [
    currentUser?.fullName,
    currentUser?.id,
    currentUser?.profileImage,
    getPost,
    isNew,
    params.postId,
  ])

  function onDropFileRejected(
    fileRejections: FileRejection[],
    event: DropEvent
  ): void {
    console.log({ fileRejections, event })
  }

  async function onDropFileAccepted(files: ExtendedFile[]): Promise<void> {
    upload(files)
  }

  async function upload(files: ExtendedFile[]): Promise<void> {
    /**
     * build upload previews
     */
    const uploadParams: UploaderProps = {
      enableChunk: false,
      files,
      onError: onUploaderError,
      onProgress: onUploaderProgress,
      onUploadDone: onUploaderUploadDone,
      shouldCreateHLS: true,
      // onCancel: onUploaderCancel,
      // onComplete: onUploaderComplete,
      // onUploadStart: onUploaderUploadStart,
    }
    const uploadPreviews: ExtendedUpload[] = await Promise.all(
      files.map(async (file) => {
        const localId = useUploader.generateUUID(2)
        const viewUrl =
          (/^image\/(jp?eg|png|gif|webp)/.test(file.type) &&
            window.URL.createObjectURL(file as Blob)) ||
          undefined
        file.localId = localId
        return {
          contentType: file.type,
          file,
          fileName: file.name,
          id: localId,
          localId,
          progress: {
            clientProgress: 0,
            uploadProgress: 0,
          },
          uploaderProp: uploadParams,
          viewUrl,
        }
      })
    )
    const existingUploads = [...(post?.uploads ?? [])] // save existing uploads before adding previews
    onChangePostInfo('uploads', uploadPreviews)

    /**
     * start uploading process
     */
    uploader.reset()
    const uploads = await uploader.create(uploadParams)
    // console.log({ uploads })

    /**
     * clears uploads and add back previews merged with uploads
     */
    const merged = mergePreviewsToUploads(uploadPreviews, uploads)
    // console.log({ merged })
    onChangePostInfo('uploads', [])
    onChangePostInfo('uploads', [...existingUploads, ...merged])
  }

  function mergePreviewsToUploads(
    previews: ExtendedUpload[],
    uploads: ExtendedUpload[]
  ): ExtendedUpload[] {
    return uploads.map((up) => {
      const viewUrl = previews.find((pv) => pv.localId === up.localId)?.viewUrl
      return {
        ...up,
        viewUrl,
      }
    })
  }

  const onClickUpload = useCallback(() => {
    if (post?.communityPostInfo?.clickThroughUrl) {
      window.open(post?.communityPostInfo.clickThroughUrl, '_blank')
    }
  }, [post])

  const onClickSave = useCallback(async () => {
    if (!uploader.checkAllComplete()) {
      sendBanner({
        autoDismiss: true,
        dismissTime: 2000,
        status: 'alert',
        message: 'Please wait for all uploads to complete',
      })
      return
    }

    const makeCreateRequestData = (
      post: ApiPostProp
    ): MutationPostCreateArgs => {
      const poll: PollInput = {
        choices:
          post.poll?.choices
            ?.map((c) => c?.desc ?? '')
            .filter((c) => c !== '') ?? [],
        expDate: pollExpDate,
        question: post.message,
      }
      return {
        allowComments,
        color: post.color,
        message: post.message,
        postTypes: ['community'],
        poll: post.poll ? poll : undefined,
        targetIds: [page.id as string],
        communityPostInfo: {
          buttonText: post.communityPostInfo?.buttonText,
          clickThroughText: post.communityPostInfo?.clickThroughText,
          clickThroughUrl: post.communityPostInfo?.clickThroughUrl,
          headline: post.communityPostInfo?.headline,
          openWebLinkForImages: post.communityPostInfo?.openWebLinkForImages,
          postTitle: post.communityPostInfo?.postTitle,
        },
        uploads: post?.uploads?.map((u) => u?.id as string) ?? [],
      }
    }

    const makeUpdateRequestData = (
      post: ApiPostProp
    ): MutationPostUpdateArgs => {
      return {
        message: post.message,
        color: post.color,
        postId: post.id,
        communityPostInfo: {
          buttonText: post.communityPostInfo?.buttonText,
          clickThroughText: post.communityPostInfo?.clickThroughText,
          clickThroughUrl: post.communityPostInfo?.clickThroughUrl,
          headline: post.communityPostInfo?.headline,
          openWebLinkForImages: post.communityPostInfo?.openWebLinkForImages,
          postTitle: post.communityPostInfo?.postTitle,
        },
      }
    }

    setIsSaving(true)
    if (isNew) {
      //TODO: Convert poll to proper input data
      const _post = makeCreateRequestData(post ?? {})
      const { ok, error } = await createPost(_post)
      if (ok) {
        sendBanner({
          autoDismiss: true,
          dismissTime: 2000,
          status: 'success',
          message: 'Community post created',
        })
        navigate(pathPosts)
      } else {
        sendBanner({
          autoDismiss: true,
          dismissTime: 2000,
          status: 'alert',
          message: `Could not create community post${
            error ? ' - ' + error.toString() : ''
          }`,
        })
      }
    } else {
      if (post) {
        const _post = makeUpdateRequestData(post)
        const { ok, error } = await updatePost(_post)
        if (ok) {
          sendBanner({
            autoDismiss: true,
            dismissTime: 2000,
            status: 'success',
            message: 'Saved community post',
          })
          navigate(pathPost)
        } else {
          sendBanner({
            autoDismiss: true,
            dismissTime: 2000,
            status: 'alert',
            message: `Could not save community post${
              error ? ' - ' + error.toString() : ''
            }`,
          })
        }

        // video poster image update
        if (videosUpdated.current.size > 0) {
          videosUpdated.current.forEach(async (image, videoId) => {
            const res = await updatePosterImage({
              uploadId: videoId,
              posterImageId: image.id,
            } as MutationUploadUpdateVideoPosterImageRequest).catch((error) =>
              console.error(error)
            )
            if (res?.ok) {
              //
            } else {
              console.error(res?.error)
            }
          })
        }
      }
    }
    setIsSaving(false)
  }, [
    currentUser?.id,
    isNew,
    navigate,
    pathPost,
    pathPosts,
    post,
    sendBanner,
    updatePost,
    uploader,
  ])

  const onClickDelete = useCallback(async () => {
    const { ok } = await deletePost({
      postId: params.postId,
    })
    if (ok) {
      navigate(pathPosts)
    }
  }, [deletePost, navigate, params.postId, pathPosts])

  const onClickCancelButton = useCallback(
    (e: any) => {
      e.preventDefault()
      sendConfirm({
        confirmText: 'Ok',
        isDestructive: true,
        message: 'Go back without saving changes?',
        onConfirm: () => {
          navigate(isNew ? pathPosts : pathPost)
        },
      })
    },
    [isNew, navigate, pathPost, pathPosts, sendConfirm]
  )

  const onChangePostInfo = useCallback(
    (field: string, value: any) => {
      setPost((prevPost) => {
        let newValue = value
        if (field === 'uploads') {
          const newSet = [...(prevPost?.uploads ?? [])]
          const incomingUploads = Array.isArray(newValue)
            ? [...newValue]
            : newValue
            ? [newValue]
            : []

          if (!newSet.length) {
            newSet.push(...incomingUploads)
          } else if (!incomingUploads.length) {
            // supplying undefined or an empty array empties out post.uploads
            newSet.splice(0)
          } else {
            for (const upload of incomingUploads) {
              const existingSetIndex = newSet.findIndex(
                (up) => up?.id === upload.id
              )
              if (existingSetIndex > -1) {
                newSet.splice(existingSetIndex, 1, upload)
              } else {
                newSet.push(upload)
              }
            }
          }

          newValue = newSet
        } else if (field === 'message' && postType === PostType.Poll) {
          // convert message to poll.question
          return {
            ...prevPost,
            poll: {
              ...prevPost?.poll,
              choices: pollOptions.map((opt, index) => ({
                desc: opt,
                id: `${index}`,
              })),
              question: newValue,
            },
          }
        } else if (field === 'poll') {
          return {
            ...prevPost,
            message: prevPost?.poll?.question ?? prevPost?.message ?? '',
            [field]: newValue,
          }
        }
        return {
          ...prevPost,
          [field]: newValue,
        }
      })
    },
    [pollOptions, postType]
  )

  const onChangeCommunityPostInfo = useCallback(
    (key: string, value?: any) => {
      setPost({
        ...post,
        communityPostInfo: {
          ...post?.communityPostInfo,
          [key]: value,
        },
      })
    },
    [post]
  )

  async function onCloseVideoThumbnailUpdateModal(
    updated?: boolean,
    video?: Upload,
    image?: Upload
  ) {
    setOpenVideoThumbnailUpdateModal(false)

    if (
      !updated ||
      !post?.uploads?.length ||
      !video?.id ||
      (!isNew && !image?.id)
    ) {
      return
    }

    const uploads = [...(post?.uploads ?? [])]
    if (isNew) {
      const { data: upload } = await getUploadUrl({ uploadId: video.id })
      if (upload) {
        const videoIndex = uploads.findIndex((up) => up?.id === video.id)
        uploads.splice(videoIndex, 1, upload)
        console.log({ videoIndex, uploads })
        onChangePostInfo('uploads', uploads)
      }
    } else {
      videosUpdated.current.set(video.id, image as Upload)
      const videoIndex = uploads.findIndex((up) => up?.id === video.id)
      if (videoIndex === -1) return
      uploads.splice(videoIndex, 1, video)
      onChangePostInfo('uploads', uploads)
    }
  }

  const onRemoveUpload = useCallback(
    (
      e: React.MouseEvent<SVGSVGElement, MouseEvent>,
      index: number,
      upload?: Upload
    ) => {
      e.preventDefault()
      e.stopPropagation()
      upload?.id && uploader.abort(upload?.id)
      const uploads = [...(post?.uploads ?? [])].filter(
        (up) => up?.id !== upload?.id
      )
      setPost({
        ...post,
        uploads,
      })
    },
    [post, uploader]
  )

  const onVideoEdit = useCallback(
    (
      e: React.MouseEvent<SVGSVGElement, MouseEvent>,
      index: number,
      upload?: Upload
    ) => {
      e.preventDefault()
      e.stopPropagation()
      // console.log('pencil icon clicked', index, upload)
      if (upload?.id && !openVideoThumbnailUpdateModal) {
        setVideoFile(upload)
        setOpenVideoThumbnailUpdateModal(true)
      }
    },
    [openVideoThumbnailUpdateModal]
  )

  const onUploaderProgress = useCallback(
    async (_uploads: ExtendedUpload[], currentUpload: ExtendedUpload) => {
      setPost((prevPost) => {
        const uploads = [...(prevPost?.uploads ?? [])]
        const currentUpIndex = uploads.findIndex(
          (up) => up?.id === currentUpload.id
        )
        if (currentUpIndex > -1) {
          const viewUrl = uploads[currentUpIndex]?.viewUrl
          if (viewUrl) {
            uploads.splice(currentUpIndex, 1, {
              ...currentUpload,
              viewUrl,
            })
          }
        }
        return {
          ...prevPost,
          uploads,
        }
      })
    },
    []
  )

  const onUploaderError = useCallback(
    async (_uploads: ExtendedUpload[], cancelledUpload: ExtendedUpload) => {
      const { progress } = cancelledUpload
      const failedStep = progress?.clientProgress !== 100 ? 'upload' : 'process'

      setPost((prevPost) => ({
        ...prevPost,
        uploads: prevPost?.uploads?.filter(
          (up) => up?.id !== cancelledUpload.id
        ),
      }))

      await removeMedia({ id: cancelledUpload.id })
      sendBanner({
        autoDismiss: false,
        status: 'alert',
        message: `Item "${
          cancelledUpload.fileName ?? ''
        }" failed to ${failedStep}.`,
      })
    },
    []
  )

  const onUploaderUploadDone = useCallback(
    async (_uploads: ExtendedUpload[], currentUpload: ExtendedUpload) => {
      await sleep(2_000)
      const { data: upload, ok } = await getUploadUrl({
        uploadId: currentUpload.id,
      })
      if (ok && upload) {
        onChangePostInfo('uploads', [upload])
      }
    },
    [onChangePostInfo]
  )

  const resetPost = useCallback(
    (type: PostType, color?: string) => {
      onChangePostInfo('uploads', [])
      setPollOptions(initialPollOptions)
      if (type === PostType.Media) {
        onChangePostInfo('color', null)
        onChangePostInfo('poll', null)
      } else if (type === PostType.Color) {
        onChangePostInfo('color', color)
        onChangePostInfo('poll', null)
      } else if (type === PostType.Poll) {
        onChangePostInfo('color', null)
        onChangePostInfo('poll', {
          ...post?.poll,
          choices: pollOptions.map((opt, index) => ({
            desc: opt,
            id: `${index}`,
          })),
          expDate: pollExpDate,
          question: post?.message,
        } as Poll)
      }
      setPostType(type)
    },
    [onChangePostInfo, pollExpDate, pollOptions, post]
  )

  const onPostTypeChange = useCallback(
    (newValue: SingleValue<Option>) => {
      const newIndex = parseInt(newValue?.value ?? '', 10)
      const color = (post?.color ?? POST_COLORS.BLUE).toLowerCase()
      if (newIndex === 0) {
        let confirmMessage
        if (post?.color) {
          confirmMessage =
            'Changing to a media post will remove the post color. Are you sure you want to continue?'
        } else if (post?.poll) {
          confirmMessage =
            'Changing to a media post will remove any poll data you have entered. Are you sure you want to continue?'
        }
        if (confirmMessage) {
          sendConfirm({
            confirmText: 'Ok',
            isDestructive: true,
            message: confirmMessage,
            onConfirm: () => resetPost(PostType.Media),
          })
        } else {
          resetPost(PostType.Media)
        }
      } else if (newIndex === 1) {
        let confirmMessage
        if (post?.uploads?.length) {
          confirmMessage =
            'Changing to a color post will remove any media you have uploaded. Are you sure you want to continue?'
        } else if (post?.poll) {
          confirmMessage =
            'Changing to a color post will remove any poll data you have entered. Are you sure you want to continue?'
        }
        if (confirmMessage) {
          sendConfirm({
            confirmText: 'Ok',
            isDestructive: true,
            message: confirmMessage,
            onConfirm: () => resetPost(PostType.Color, color),
          })
        } else {
          resetPost(PostType.Color, color)
        }
      } else if (newIndex === 2) {
        let confirmMessage
        if (post?.uploads?.length) {
          confirmMessage =
            'Changing to a poll post will remove amy media you have uploaded. Are you sure you want to continue?'
        } else if (post?.color) {
          confirmMessage =
            'Changing to a poll post will remove the post color. Are you sure you want to continue?'
        }
        if (confirmMessage) {
          sendConfirm({
            confirmText: 'Ok',
            isDestructive: true,
            message: confirmMessage,
            onConfirm: () => resetPost(PostType.Poll),
          })
        } else {
          resetPost(PostType.Poll)
        }
      }
    },
    [post, resetPost, sendConfirm]
  )

  useEffect(() => {
    async function checkUser() {
      const res = await getUser().catch((err) => console.error(err))
      const userData: User = res?.data as User
      setCurrentUser(userData)
    }

    if (currentUser) {
      fetchData()
    } else {
      checkUser()
    }
  }, [currentUser, fetchData, getPost, getUser])

  const handleOnChangePollOption = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    // update poll values
    const _pollOptions = pollOptions.map((option, _index) =>
      index === _index ? event.target.value : option
    )
    // for left column ui
    setPollOptions(_pollOptions)

    const _poll: Poll = {
      ...post?.poll,
      choices: _pollOptions.map((opt, index) => ({
        desc: opt,
        id: `${index}`,
      })),
      question: post?.poll?.question ?? post?.message,
      // expDate: post?.poll.e'',
    }

    // update post info
    onChangePostInfo('poll', _poll)
  }

  const handleOnClickAddPollOption = () => {
    const _pollOptions = [...pollOptions, '']
    const _poll: Poll = {
      ...post?.poll,
      choices: _pollOptions.map((opt, index) => ({
        desc: opt,
        id: `${index}`,
      })),
      question: post?.poll?.question ?? post?.message,
    }
    setPollOptions(_pollOptions)
    onChangePostInfo('poll', _poll)
  }

  const handleOnClickRemovePollOption = (index: number) => {
    const _pollOptions = pollOptions.filter((_, _index) => index !== _index)
    const _poll: Poll = {
      ...post?.poll,
      choices: _pollOptions.map((opt, index) => ({
        desc: opt,
        id: `${index}`,
      })),
      question: post?.poll?.question ?? post?.message,
    }
    setPollOptions(_pollOptions)
    onChangePostInfo('poll', _poll)
  }

  const handleOnChangePollExpDate = (date: Date) => {
    const minDate = moment()
      .set('minutes', 0)
      .add(2, 'hours')
      .subtract(15, 'minutes')
    if (moment(date).isBefore(minDate)) {
      sendBanner({
        autoDismiss: true,
        dismissTime: 3000,
        status: 'info',
        message:
          'End date/time chosen is invalid and has been reset to minimum allowed date/time.',
      })
      date = minDate.toDate()
    }
    setPollExpDate(date.toISOString())
    onChangePostInfo('poll', {
      ...post?.poll,
      expDate: date.toISOString(),
    } as Poll)
  }
  useEffect(() => {
    console.log({ post })
  }, [post])

  const minPollExpTime = useMemo(() => {
    const start = moment(pollExpDate).format('MM-DD-YYYY')

    if (moment(start).isAfter(initialPollExpDate)) {
      return moment(pollExpDate).startOf('day').toDate()
    }
    return moment(initialPollExpDate).toDate()
  }, [pollExpDate])
  const renderNewInputs = () => {
    if (postType === PostType.Media) {
      return (
        <TextInputContainer>
          <InputContainer
            {...getRootProps({
              className: 'dropzone',
              role: 'button',
            })}
          >
            <InputContainerLabel>Media</InputContainerLabel>
            <input {...getInputProps()} />
            {!post?.uploads?.length && !videoUploadsInProgress?.length && (
              <InputContainerEmpty>
                {isDragActive
                  ? 'Drop the files here ...'
                  : 'Drag and drop your file here, or click to select'}
              </InputContainerEmpty>
            )}
            {!!videoUploadsInProgress?.length && (
              <UploadThumbs>
                {videoUploadsInProgress?.map((upload) => (
                  <UploadThumbContainer key={upload.id}>
                    <Spinner fill={Colors.WHITE} />
                  </UploadThumbContainer>
                ))}
              </UploadThumbs>
            )}
            {!!post?.uploads?.length && (
              <UploadThumbs>
                {post?.uploads?.map((upload, index) => {
                  if (isFile(upload?.contentType as string)) {
                    return (
                      <UploadThumbContainerFile key={upload?.id}>
                        <ThumbX
                          name='X'
                          onClick={(
                            e: React.MouseEvent<SVGSVGElement, MouseEvent>
                          ) => onRemoveUpload(e, index, upload as Upload)}
                        />
                        <FileTypeIcon
                          contentType={upload?.contentType as string}
                          fileName={upload?.fileName as string}
                          nameCase='pascal'
                          size={70}
                        />
                      </UploadThumbContainerFile>
                    )
                  }
                  const thumbSrc = getTranscodeUrl({
                    upload: (upload?.transcodes as ApiImageProp[]) ?? [],
                    transcodeTypes: ['THUMB', 'feedLarge', 'feed', 'media'],
                  })
                  const previewDataUrl = upload?.viewUrl?.startsWith('blob')
                    ? upload?.viewUrl
                    : undefined
                  const src = thumbSrc || previewDataUrl
                  return (
                    <UploadThumbContainer key={upload?.id}>
                      <ThumbX
                        name='X'
                        onClick={(
                          e: React.MouseEvent<SVGSVGElement, MouseEvent>
                        ) => onRemoveUpload(e, index, upload as Upload)}
                      />
                      {!thumbSrc && !previewDataUrl && (
                        <Spinner fill={Colors.WHITE} />
                      )}
                      {!!src && (
                        <>
                          <UploadThumb key={index} src={src} />
                          {!thumbSrc && (
                            <SpinnerContainer>
                              <Spinner fill={Colors.WHITE} />
                            </SpinnerContainer>
                          )}
                        </>
                      )}
                      {!!thumbSrc && isVideo(upload?.contentType ?? '') && (
                        <ThumbEdit
                          name='Pencil'
                          onClick={(
                            e: React.MouseEvent<SVGSVGElement, MouseEvent>
                          ) => onVideoEdit(e, index, upload as Upload)}
                        />
                      )}
                    </UploadThumbContainer>
                  )
                })}
              </UploadThumbs>
            )}
            <Button variant='alternate'>Select Image/Video/File</Button>
          </InputContainer>
        </TextInputContainer>
      )
    } else if (postType === PostType.Color) {
      return (
        <TextInputContainer>
          <InputContainer>
            <InputContainerLabel>Post Color</InputContainerLabel>
            <ColorsContainer>
              {Object.keys(POST_COLORS).map((color: string, index: number) => {
                const _color = POST_COLORS[color as keyof typeof POST_COLORS]
                return (
                  <ColorOption
                    aria-selected={post?.color === _color}
                    $color={_color}
                    key={`${index}_color-option-${_color}`}
                    onClick={() => onChangePostInfo('color', _color)}
                  />
                )
              })}
            </ColorsContainer>
          </InputContainer>
        </TextInputContainer>
      )
    } else if (postType === PostType.Poll) {
      return (
        <TextInputContainer>
          <InputContainer>
            <InputContainerLabel>Poll Options</InputContainerLabel>
            {pollOptions?.map((option, index) => (
              <PollInputContainer key={index}>
                <TextInputStyled
                  onChange={(event) => handleOnChangePollOption(event, index)}
                  placeholder={`Option ${index + 1}`}
                  containerStyle={{
                    // marginTop: 10,
                    minHeight: 1,
                  }}
                  // style={{ margin: 0 }}
                  value={option}
                />
                {pollOptions.length > 2 && (
                  <RemovePollOptionButton
                    onClick={() => handleOnClickRemovePollOption(index)}
                  >
                    <Icon name='X' fill={Colors.SHUTTLE_GRAY} size={14} />
                  </RemovePollOptionButton>
                )}
              </PollInputContainer>
            ))}

            {pollOptions.length < 5 && (
              <AddPollOptionButton onClick={handleOnClickAddPollOption}>
                + Add Another Option
              </AddPollOptionButton>
            )}
            <DatePickerContainer>
              <DatePicker
                dateFormat='MM/dd/yyyy h:mm aa'
                id='start'
                label='Poll Ends On'
                maxTime={moment(pollExpDate).endOf('day').toDate()}
                minDate={moment(initialPollExpDate).toDate()}
                minTime={minPollExpTime}
                name='start'
                onChange={handleOnChangePollExpDate}
                selected={moment(pollExpDate).toDate()}
                shouldCloseOnSelect
                showTimeSelect
              />
            </DatePickerContainer>
          </InputContainer>
        </TextInputContainer>
      )
    }
    return null
  }

  return (
    <ContentContainer>
      <Helmet>
        <title>sportsYou Pages</title>
      </Helmet>
      <Sticky bottomBoundary='#footeractions' innerZ={1}>
        <Header>
          <HeaderTitle>
            <Link to={pathPortal} title='Pages Portal'>
              <Icon name='Home' fill={Colors.BLACK} width={18} />
            </Link>
            <span>
              <HeaderTitleChevron />
              <StyledLink to={pathCommunity}>{page.name}</StyledLink>
            </span>
            <span>
              <HeaderTitleChevron />
              <StyledLink to={pathPosts}>Posts</StyledLink>
            </span>
            {!isNew ? (
              <span>
                <HeaderTitleChevron />
                <Link
                  onClick={onClickCancelButton}
                  title={post?.communityPostInfo?.postTitle ?? 'Post Detail'}
                  to={pathPost}
                >
                  {post?.communityPostInfo?.postTitle ?? 'Post Detail'}
                </Link>
              </span>
            ) : null}
            <span>
              <HeaderTitleChevron />
              {isNew ? 'Create' : 'Edit'}
            </span>
          </HeaderTitle>
          {isEditable && (
            <PostActions>
              <CancelButton
                onClick={onClickCancelButton}
                to={isNew ? pathPosts : pathPost}
              >
                Cancel
              </CancelButton>
              <Button
                disabled={isSaving}
                loading={isSaving}
                onClick={onClickSave}
              >
                {isNew ? 'Save and Close' : 'Save and Close'}
              </Button>
            </PostActions>
          )}
        </Header>
      </Sticky>
      {!isLoading && (
        <>
          <Columns>
            <LeftColumn>
              <H1>{isNew ? 'Create a Post' : 'Edit Post'}</H1>
              <TextInputContainer>
                <TextInputStyled
                  label='Post Title'
                  type='text'
                  placeholder='My Post'
                  value={post?.communityPostInfo?.postTitle ?? ''}
                  onChange={(e) =>
                    onChangeCommunityPostInfo('postTitle', e.target.value)
                  }
                  autoFocus={isNew}
                  containerStyle={{ width: '100%' }}
                />
              </TextInputContainer>
              <H1>Post Content</H1>
              <TextInputContainer>
                <TextAreaStyled
                  autoGrow
                  containerStyle={{ width: '100%' }}
                  disabled={!isEditable}
                  label={postType === PostType.Poll ? 'Question' : 'Message'}
                  onChange={(e) => onChangePostInfo('message', e.target.value)}
                  placeholder={
                    postType === PostType.Poll
                      ? 'Ask a question'
                      : "Example: What's going on?"
                  }
                  value={
                    (postType === PostType.Poll
                      ? post?.poll?.question
                      : post?.message) ?? ''
                  }
                />
              </TextInputContainer>
              <TextInputContainer>
                <InputContainer>
                  <InputContainerLabel>Post Type</InputContainerLabel>
                  <Select
                    autoFocus={isNew}
                    isMulti={false}
                    onChange={onPostTypeChange}
                    options={postTypeOptions}
                    placeholder='Post Type'
                    value={postTypeOptions[postType]}
                    isDisabled={!isNew}
                  />
                </InputContainer>
              </TextInputContainer>

              {isNew && renderNewInputs()}

              {!isNew && !!post?.uploads?.length && (
                <TextInputContainer>
                  <InputContainerLabel>Media</InputContainerLabel>
                  <input {...getInputProps()} />
                  {!!post?.uploads?.length && (
                    <UploadThumbs>
                      {post?.uploads?.map((upload, index) => {
                        const thumbSrc = upload?.transcodes
                          ?.filter((tr) =>
                            ['THUMB', 'feed'].includes(tr?.transcodeType ?? '')
                          )
                          .shift()?.viewUrl
                        return (
                          <UploadThumbContainer key={upload?.id}>
                            {!thumbSrc && <Spinner fill={Colors.WHITE} />}
                            {!!thumbSrc && (
                              <UploadThumb key={index} src={thumbSrc} />
                            )}
                            {thumbSrc && isVideo(upload?.contentType ?? '') && (
                              <ThumbEdit
                                name='Pencil'
                                onClick={(
                                  e: React.MouseEvent<SVGSVGElement, MouseEvent>
                                ) => onVideoEdit(e, index, upload)}
                              />
                            )}
                          </UploadThumbContainer>
                        )
                      })}
                    </UploadThumbs>
                  )}
                </TextInputContainer>
              )}
              <TextInputContainer>
                <InputContainer>
                  <InputContainerLabel>Disable Comments</InputContainerLabel>
                  <label>
                    <input
                      // checked={!allowComments}
                      // disabled={!isEditable}
                      checked
                      disabled
                      onChange={() => setAllowComments((prev) => !prev)}
                      type={'checkbox'}
                    />{' '}
                    Disable comments
                  </label>
                </InputContainer>
              </TextInputContainer>

              {/* <TextInputContainer>
                <TextInputStyled
                  label="Click-through URL"
                  type="text"
                  placeholder="Example: https://www.example.com/my-landing-page"
                  value={post?.communityPostInfo?.clickThroughUrl ?? ''}
                  onChange={e =>
                    onChangeCommunityPostInfo('clickThroughUrl', e.target.value)
                  }
                  containerStyle={{ width: '100%' }}
                  disabled={!isEditable}
                />
              </TextInputContainer>
              <TextInputContainer>
                <TextInputStyled
                  label="Click-through Destination Description"
                  type="text"
                  placeholder="Example: example.com"
                  value={post?.communityPostInfo?.clickThroughText ?? ''}
                  onChange={e =>
                    onChangeCommunityPostInfo(
                      'clickThroughText',
                      e.target.value,
                    )
                  }
                  containerStyle={{ width: '100%' }}
                  disabled={!isEditable}
                />
              </TextInputContainer>
              <TextInputContainer>
                <TextInputStyled
                  label="Call to action Headline"
                  type="text"
                  placeholder="Example: Your headline"
                  value={post?.communityPostInfo?.headline ?? ''}
                  onChange={e =>
                    onChangeCommunityPostInfo('headline', e.target.value)
                  }
                  containerStyle={{ width: '100%' }}
                  disabled={!isEditable}
                />
              </TextInputContainer>
              <TextInputContainer>
                <TextInputStyled
                  label="Call to action Tagline"
                  type="text"
                  placeholder="Example: More info"
                  value={post?.communityPostInfo?.tagline ?? ''}
                  onChange={e =>
                    onChangeCommunityPostInfo('tagline', e.target.value)
                  }
                  containerStyle={{ width: '100%' }}
                  disabled={!isEditable}
                />
              </TextInputContainer>
              <TextInputContainer>
                <TextInputStyled
                  label="Call to action Button Text"
                  type="text"
                  placeholder="Example: Learn More"
                  value={post?.communityPostInfo?.buttonText ?? ''}
                  onChange={e =>
                    onChangeCommunityPostInfo('buttonText', e.target.value)
                  }
                  containerStyle={{ width: '100%' }}
                  disabled={!isEditable}
                />
              </TextInputContainer> */}
            </LeftColumn>
            <RightColumn>
              <Sticky bottomBoundary='#footeractions' top={75}>
                <PostContainer>
                  <H1>Preview</H1>
                  <PostWrapper>
                    <PostComponentStyled
                      createdAt={moment().toISOString()}
                      {...post}
                      community={page}
                      onClickUpload={onClickUpload}
                      hideFollowButton
                    />
                  </PostWrapper>
                </PostContainer>
              </Sticky>
            </RightColumn>
          </Columns>
          <TableTools id='footeractions'>
            <TableToolsLeft>
              {!isNew && !post?.deletedAt && post?.canDelete && (
                <>
                  <hr />
                  <Button variant={'danger'} onClick={onClickDelete}>
                    Deactivate This Post
                  </Button>
                </>
              )}
            </TableToolsLeft>
            <TableToolsRight></TableToolsRight>
          </TableTools>
        </>
      )}
      {!!isLoading && <Spinner />}
      <VideoThumbnailUpdateModal
        isOpen={openVideoThumbnailUpdateModal}
        isPostMedia={!isNew}
        onClose={onCloseVideoThumbnailUpdateModal}
        videoFile={videoFile as ExtendedUpload}
      />
    </ContentContainer>
  )
}

const H1 = styled.h1`
  margin-bottom: 10px;
  font-size: 20px;
`

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const LeftColumn = styled.div`
  width: 40%;
  margin-top: 1rem;
`

const RightColumn = styled.div`
  width: 60%;
  margin-left: 1rem;
  margin-top: 1rem;
`

const PostContainer = styled.div`
  align-items: flex-start;
  background-color: ${Colors.ALTO};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0rem 2rem 2rem 2rem;
  min-height: 100vh;
  border-radius: 8px;
`
const PostWrapper = styled.div`
  /* max-width: 400px;
  min-width: 400px; */
  width: 100%;
`

const PostComponentStyled = styled(PostComponent)`
  /* width: 400px; */
`

const TextInputContainer = styled.div`
  margin-bottom: 1rem;
`
const PollInputContainer = styled.div`
  align-items: center;
  display: flex;
  &:not(:first-child) {
    margin-top: 10px;
  }
`
const PollButton = css`
  background: none;
  border: none;
  border-radius: ${BORDER_RADIUS};
  cursor: pointer;
  outline: none;
  transition: background-color 120ms ease-in-out;
  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
  &:focus {
    box-shadow: 0 0 0 2px ${lighten(Colors.HAVELOCK_BLUE, 40)};
  }
`
const AddPollOptionButton = styled.button`
  ${PollButton};
  color: ${Colors.HAVELOCK_BLUE};
  margin-top: 15px;
  padding: 6px 12px;
`
const RemovePollOptionButton = styled.button`
  ${PollButton};
  border-radius: 50%;
  height: 36px;
  margin-left: 6px;
  width: 36px;
  flex: 0 0 36px;
  padding: 0;
`
const TextInputStyled = styled(TextInput)`
  width: 650px;
`

const TextAreaStyled = styled(TextArea)`
  width: 650px;
`

const InputContainer = styled.div`
  border: 1px solid #dddddd;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 10px;
`

const InputContainerLabel = styled.div`
  align-items: center;
  color: ${Colors.PUNCH};
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: 700;
`

const InputContainerEmpty = styled.div`
  border-radius: 8px;
  border: 1px dashed #dddddd;
  color: ${Colors.SHUTTLE_GRAY};
  cursor: pointer;
  margin: 10px 0;
  padding: 10px;
`

const UploadThumbs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1rem;
  width: 650px;
`

const UploadThumbContainer = styled.div`
  align-items: center;
  background-color: ${Colors.MINE_SHAFT};
  border-radius: 8px;
  border: 1px solid ${Colors.ALTO};
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100px;
`
const UploadThumbContainerFile = styled(UploadThumbContainer)`
  background-color: ${Colors.WHITE};
`

const ThumbX = styled(Icon)`
  background-color: ${Colors.WHITE};
  border-radius: 50%;
  border: 1px solid ${Colors.SHUTTLE_GRAY};
  color: ${Colors.SHUTTLE_GRAY};
  cursor: pointer;
  font-size: 20px;
  padding: 3px;
  position: absolute;
  right: 0;
  top: 0;
`

const ThumbEdit = styled(Icon)`
  background-color: ${Colors.WHITE};
  border-radius: 25%;
  border: 1px solid ${Colors.SHUTTLE_GRAY};
  color: ${Colors.SHUTTLE_GRAY};
  cursor: pointer;
  font-size: 20px;
  padding: 3px;
  position: absolute;
  left: 0;
  top: 0;
`

const UploadThumb = styled.img`
  max-height: 100px;
  max-width: 100px;
`

const PostActions = styled.div`
  align-items: center;
  display: flex;
`

const CancelButton = styled(Link)`
  margin-right: 40px;
  text-decoration: none;
  color: ${Colors.HAVELOCK_BLUE};
`

const StyledLink = styled(Link)`
  color: ${Colors.BLACK};
`

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ColorsContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: space-around;
  padding: 10px 4px;
  @media all and (min-width: 640px) {
    padding: 10px 0;
  }
`
const ColorOption = styled.div<{ $color: string }>`
  align-items: center;
  background-color: ${({ $color }) => $color};
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  transition: background-color 0.2s ease-in-out;
  height: 28px;
  width: 28px;

  @media all and (min-width: 640px) {
    height: 38px;
    width: 38px;
  }

  &::before {
    content: '';
    border: 2px solid transparent;
    border-radius: 50%;
    box-sizing: inherit;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    position: absolute;
    height: 38px;
    width: 38px;
    @media all and (min-width: 640px) {
      height: 48px;
      width: 48px;
    }
  }

  &[aria-selected='false']:active::before,
  &[aria-selected='false']:hover::before {
    border-color: ${Colors.DUSTY_GRAY};
  }

  &[aria-selected='true']::before {
    border-color: ${Colors.MINE_SHAFT};
  }
`

const DatePickerContainer = styled.div`
  display: flex;
  margin-top: 10px;
`
