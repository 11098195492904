import { FC, useMemo } from 'react'
import styled from 'styled-components'

import { Community, Image, Post } from '@sportsyou/api'
import {
  Colors,
  formatTimestamp,
  getImageByTranscodeType,
} from '@sportsyou/core'

import Avatar from '../../avatar'

import { PostMenu, PostMenuAction } from './menu'
import Icon from '../../icon'

type SomePostProps = Pick<
  Post,
  'createdAt' | 'createdBy' | 'isEdited' | 'sponsoredPostInfo'
>

export interface PostHeaderProps extends SomePostProps {
  canPost?: boolean
  community?: Community
  hideMenu?: boolean
  isSponsoredPost?: boolean
  menuActions?: Array<PostMenuAction>
  onClickProfileName?: () => void
  onClickAvatar?: () => void
}

// type MenuActionPerms = Pick<
//   PostProps,
//   | 'allowComments'
//   | 'isOwner'
//   | 'canDelete'
//   | 'canEdit'
//   | 'canPin'
//   | 'poll'
//   | 'isAnnouncement'
//   | 'isPinned'
//   | 'team'
// >

/*
isOwner
  canEdit
  poll
  allowComments
  canPin
  isPinned
  hidePinPost
  team.type
*/

export const PostHeader: FC<PostHeaderProps> = ({
  createdAt,
  createdBy,
  community,
  hideMenu,
  isEdited,
  isSponsoredPost,
  menuActions,
  onClickProfileName,
  onClickAvatar,
  sponsoredPostInfo,
}: PostHeaderProps) => {
  const getProfileImage = useMemo(() => {
    if (community) {
      return getImageByTranscodeType(
        (community.profileImage as Image[]) ?? [],
        'profileImage'
      )
    }

    if (isSponsoredPost && sponsoredPostInfo?.aliasUser) {
      return getImageByTranscodeType(
        (sponsoredPostInfo.aliasUser.profileImage as Image[]) ?? [],
        'profileImage'
      )
    }

    return getImageByTranscodeType(
      (createdBy?.profileImage as Image[]) ?? [],
      'profileImage'
    )
  }, [
    community,
    createdBy?.profileImage,
    isSponsoredPost,
    sponsoredPostInfo?.aliasUser,
  ])

  const getName = useMemo(() => {
    if (community) {
      return community.name ?? 'sportsYou Community'
    }
    if (createdBy?.fullName) {
      return createdBy?.fullName
    }
    if (createdBy?.firstName && createdBy.lastName) {
      return `${createdBy.firstName} ${createdBy.lastName}`
    }
    return createdBy?.firstName ?? 'SportsYou User'
  }, [
    community,
    createdBy?.firstName,
    createdBy?.fullName,
    createdBy?.lastName,
  ])

  const handleOnClickAvatar = () => {
    onClickAvatar?.()
    !onClickAvatar && handleOnClickName()
  }

  const handleOnClickName = () => {
    onClickProfileName?.()
  }

  return (
    <Container>
      <Avatar
        diameter={40}
        name={getName}
        onClick={handleOnClickAvatar}
        uri={getProfileImage}
      />
      <AuthorContainer
        aria-label={`Profile for ${getName}`}
        onClick={handleOnClickName}
        title={`Profile for ${getName}`}
      >
        {community ? (
          <Author>
            {getName}
            <Icon
              name='BadgeVerifiedOutline'
              size={16}
              style={{ marginLeft: 8, marginTop: -1 }}
            />
          </Author>
        ) : (
          <Author>{getName}</Author>
        )}
        {isSponsoredPost ? (
          <Subtext>Sponsored</Subtext>
        ) : (
          createdAt && (
            <Subtext>
              {formatTimestamp(createdAt, true, true)}
              {isEdited ? ' (Edited)' : ''}
            </Subtext>
          )
        )}
      </AuthorContainer>

      {!hideMenu && <PostMenu actions={menuActions} />}
    </Container>
  )
}

const Container = styled.header`
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;
  display: flex;
  margin-left: 12px;
  margin-right: 12px;
  padding-bottom: 10px;
  padding-top: 12px;
`
const Author = styled.span`
  align-items: center;
  display: flex;
  // flex-direction: column;
`
const AuthorContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-left: 12px;

  &:hover ${Author}, &:active ${Author} {
    text-decoration: underline;
  }
`
const Subtext = styled.span`
  color: ${Colors.DUSTY_GRAY};
  font-size: 12px;
  line-height: 1;
`

export default PostHeader
