import React from 'react'

import DataTable, {
  SortOrder,
  TableColumn,
  TableStyles,
} from 'react-data-table-component'

export interface TableProps {
  columns: TableColumn<any>[]
  customStyles?: TableStyles
  data: any[]
  defaultSortAsc?: boolean
  defaultSortFieldId?: string | number
  dense?: boolean
  disabled?: boolean
  fixedHeader?: boolean
  fixedHeaderScrollHeight?: string
  header?: boolean
  headerClassName?: string
  headerStyle?: React.CSSProperties
  highlightOnHover?: boolean
  noDataComponent?: React.ReactNode
  noTableHead?: boolean
  onRowClicked?: (row: any) => void
  onRowDoubleClicked?: (row: any) => void
  onSelectedRowsChange?: (selected: {
    allSelected: boolean
    selectedCount: number
    selectedRows: any[]
  }) => void
  onSort?: (selectedColumn: TableColumn<any>, sortDirection: SortOrder) => void
  persistTableHead?: boolean
  pointerOnHover?: boolean
  progressComponent?: React.ReactNode
  progressPending?: boolean
  responsive?: boolean
  selectableRows?: boolean
  sortIcon?: React.ReactNode
  striped?: boolean
  sortFunction?: (rows: any, selector: any, direction: SortOrder) => []
  pagination?: boolean
}

export const Table: React.FC<TableProps> = (props: TableProps) => (
  <DataTable {...props} />
)

export default Table
