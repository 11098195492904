import { Helmet } from 'react-helmet-async'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  SponsoredPostStats,
  querySponsoredPostReach,
  querySponsoredPostStats,
  queryPost,
  Post,
} from '@sportsyou/api'
import {
  Button,
  CopyToClipboard,
  DateRange,
  Icon,
  Spinner,
  Table,
  Tabs,
  Select,
  Well,
  Skeleton,
} from '@sportsyou/react-dom-ui'
import { exportCSV, arrayToCVS, Colors, secondsToTime } from '@sportsyou/core'
import { useDispatch, useSelector } from 'react-redux'
import { useFetchApi } from '@sportsyou/react-hooks'

import {
  Header,
  HeaderTitle,
  TableTools,
  TableToolsLeft,
  TableToolsRight,
} from '.'
import {
  ContentContainer,
  HeaderTitleChevron,
} from '../../styles/global-styles'
import {
  selectSponsoredPostById,
  selectSponsoredPostsStatsByPostId,
  setSponsoredPost,
  setSponsoredPostsStats,
  updateSponsoredPost,
} from '../../store/slices'
// import SponsoredPostsModeration from './SponsoredPostsModeration'

export type SponsoredPostsDetailTab = 'metrics' | 'moderation' | 'edit'

interface SponsoredPostsDetailProps {
  postId?: string
  tab?: SponsoredPostsDetailTab
}

const SponsoredPostsDetail = (props: SponsoredPostsDetailProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const post = useSelector((state) => selectSponsoredPostById(state, params.id))
  const stats = useSelector((state) =>
    selectSponsoredPostsStatsByPostId(state, params.id)
  )

  const [endDate, setEndDate] = useState<Date>(() => {
    if (post?.deletedAt) {
      return new Date(post?.deletedAt)
    } else if (post?.sponsoredPostInfo?.endDate) {
      return new Date(post.sponsoredPostInfo.endDate)
    } else {
      return new Date()
    }
  })
  const [startDate, setStartDate] = useState<Date>(() => {
    if (post?.sponsoredPostInfo?.startDate) {
      return new Date(post.sponsoredPostInfo.startDate)
    } else {
      return new Date(new Date().setDate(new Date().getDate() - 30))
    }
  })
  const [isLoading, setIsLoading] = useState(true)
  const [reach, setReach] = useState<number>(0)
  const [teamRole, setTeamRole] = useState<string | undefined>()

  const { fetch: getSponsoredPostReach } = useFetchApi(querySponsoredPostReach)
  const { fetch: getSponsoredPostsStats } = useFetchApi(querySponsoredPostStats)
  const { fetch: getSponsoredPostDetails } = useFetchApi(queryPost)

  const hidesFormat = (data: any) => {
    return (
      <>
        <div>{data.hiddenCount}</div>
        {data.hiddenStats.map((stat: any) => {
          if (stat?.reasonDesc?.length) {
            return (
              <div>
                {stat.reasonDesc}: {stat.count}
              </div>
            )
          } else {
            return null
          }
        })}
      </>
    )
  }

  const sponsoredPostsDataColumns = useMemo(
    () => [
      {
        name: <>Date</>,
        selector: (row: SponsoredPostStats) => row?.label ?? '',
        sortable: true,
        reorder: true,
        minWidth: '100px',
      },
      {
        name: <>Views</>,
        selector: (row: SponsoredPostStats) => row?.postViewedCount ?? 0,
        sortable: true,
        reorder: true,
        minWidth: '20px',
      },
      {
        name: <>Clicks</>,
        selector: (row: SponsoredPostStats) => row?.clickThroughCount ?? 0,
        sortable: true,
        reorder: true,
        minWidth: '20px',
      },
      {
        name: <>Likes</>,
        selector: (row: SponsoredPostStats) => row?.likeCount ?? 0,
        sortable: true,
        reorder: true,
        minWidth: '20px',
      },
      {
        name: <>Comments</>,
        selector: (row: SponsoredPostStats) => row?.commentCount ?? 0,
        sortable: true,
        reorder: true,
        minWidth: '20px',
      },
      {
        name: <>Video Views</>,
        selector: (row: SponsoredPostStats) => row?.videoViewsCount ?? 0,
        sortable: true,
        reorder: true,
        minWidth: '20px',
      },
      {
        name: <>Min Time Video Views</>,
        selector: (row: SponsoredPostStats) =>
          secondsToTime(row?.videoNumberOfViewsOverMinViewedTime ?? 0),
        minWidth: '20px',
      },
      {
        name: <>Total Video Time</>,
        selector: (row: SponsoredPostStats) =>
          secondsToTime(row?.videoTotalViewedTime ?? 0),
        minWidth: '20px',
      },
      {
        name: <>Avg Video Time</>,
        selector: (row: SponsoredPostStats) =>
          secondsToTime(row?.videoAverageViewedTime ?? 0),
        minWidth: '20px',
      },
      {
        name: <>Hides</>,
        selector: (row: SponsoredPostStats) => row?.hiddenCount ?? 0,
        sortable: true,
        reorder: true,
        format: hidesFormat,
        minWidth: '20px',
      },
    ],
    []
  )

  const refreshReach = useCallback(
    async ({ postId, teamRole }: { postId: string; teamRole?: string }) => {
      const { data, ok } = await getSponsoredPostReach({
        postId,
        teamRole,
      })
      if (ok) {
        setReach(data?.activeUserCount ?? 0)
      }
    },
    [getSponsoredPostReach]
  )

  const generateCSVData = useCallback(() => {
    return (
      stats?.map((stat) => {
        return {
          Date: stat.label,
          Views: stat.postViewedCount ?? 0,
          Clicks: stat.clickThroughCount ?? 0,
          Likes: stat.likeCount ?? 0,
          // Comments: stat.commentCount ?? 0,
          'Video Views': stat.videoViewsCount ?? 0,
          'Min Time Video Views': secondsToTime(
            stat.videoNumberOfViewsOverMinViewedTime ?? 0
          ),
          'Total Video Time': secondsToTime(stat.videoTotalViewedTime ?? 0),
          'Avg Video Time': secondsToTime(stat.videoAverageViewedTime ?? 0),
          Hides: stat.hiddenCount ?? 0,
        }
      }) ?? []
    )
  }, [stats])

  const copyCSVText = useCallback(() => {
    return arrayToCVS(generateCSVData())
  }, [generateCSVData])

  const onClickDownloadCSV = useCallback(() => {
    exportCSV(generateCSVData(), 'sponsored-posts.csv')
  }, [generateCSVData])

  const fetchStats = useCallback(async () => {
    if (params.id) {
      const { data: _stats } = await getSponsoredPostsStats({
        postId: params.id,
        endDate: endDate?.toISOString(),
        startDate: startDate?.toISOString(),
        teamRole: teamRole,
      })

      if (_stats) {
        dispatch(
          setSponsoredPostsStats({ postId: params.id as string, stats: _stats })
        )
      }

      refreshReach({
        postId: params.id,
        teamRole: teamRole,
      })

      setIsLoading(false)
    }
  }, [
    getSponsoredPostsStats,
    params.id,
    endDate,
    startDate,
    teamRole,
    refreshReach,
    dispatch,
  ])

  const handleOnClickMetricsTab = useCallback(
    () => navigate(`/sponsored-posts/${params.id}/metrics`),
    [navigate, params.id]
  )

  const handleOnClickEditTab = useCallback(
    () => navigate(`/sponsored-posts/${params.id}/edit`),
    [navigate, params.id]
  )

  useEffect(() => {
    const loadPost = async () => {
      const currentPost = await getSponsoredPostDetails({ id: params.id })
      dispatch(setSponsoredPost(currentPost.data as Post))
      if (currentPost?.data?.approvedAt) {
        setStartDate(new Date(currentPost.data.approvedAt))
      }
      if (currentPost?.data?.deletedAt) {
        setEndDate(new Date(currentPost.data.deletedAt))
      }
    }
    loadPost()
  }, [dispatch, getSponsoredPostDetails, params.id])

  useEffect(() => {
    fetchStats()
  }, [fetchStats])

  const adName =
    post?.sponsoredPostInfo?.adName ?? (isLoading ? '...' : 'Untitled')

  const renderMetricsTab = () => {
    return (
      <>
        <TableTools>
          <TableToolsLeft>
            <DateRange
              endDate={endDate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
              startDate={startDate}
            />
            <StyledSelect initialValue='All Roles' label='Role'>
              <Select.Option
                isSelected={!teamRole}
                onClick={() => setTeamRole(undefined)}
                value='All Roles'
              >
                All Roles
              </Select.Option>
              <Select.Option
                isSelected={teamRole === 'player'}
                onClick={() => setTeamRole('player')}
                value='Players'
              >
                Players
              </Select.Option>
              <Select.Option
                isSelected={teamRole === 'coach'}
                onClick={() => setTeamRole('coach')}
                value='Coaches'
              >
                Coaches
              </Select.Option>
              <Select.Option
                isSelected={teamRole === 'parent'}
                onClick={() => setTeamRole('parent')}
                value='Family'
              >
                Family
              </Select.Option>
              <Select.Option
                isSelected={teamRole === 'other'}
                onClick={() => setTeamRole('other')}
                value='Group Members'
              >
                Group Members
              </Select.Option>
            </StyledSelect>
            <StyledWell noBorder label='Audience Reach'>
              {reach}
            </StyledWell>
          </TableToolsLeft>
          <TableToolsRight>
            <Button
              appearance='ghost'
              variant='alternate'
              onClick={onClickDownloadCSV}
            >
              Download CSV
            </Button>
            <CopyToClipboard
              content={() => (
                <Button appearance='ghost' variant='alternate'>
                  Copy CSV
                </Button>
              )}
              contentOnCopy={() => (
                <Button appearance='ghost' variant='alternate'>
                  Copied!
                </Button>
              )}
              text={copyCSVText}
            />
          </TableToolsRight>
        </TableTools>
        <Table
          // onRowClicked={() => {}}
          // pointerOnHover
          // selectableRows
          columns={sponsoredPostsDataColumns}
          customStyles={customTableStyles}
          data={stats ?? []}
          dense
          striped
          progressPending={isLoading && !stats?.length}
          progressComponent={
            <Skeleton
              barCount={10}
              barGap={4}
              barHeight={26}
              showBars
              showHeader={true}
              width={'100%'}
            />
          }
        />
      </>
    )
  }

  return (
    <ContentContainer>
      <Helmet>
        <title>{adName} - sportsYou Sponsored Posts</title>
      </Helmet>
      <Header>
        <HeaderTitle>
          <Link to={'/sponsored-posts'} title='Sponsored Posts Home'>
            <Icon name='Home' fill={Colors.BLACK} width={18} />
          </Link>
          <span>
            <HeaderTitleChevron />
            {adName}
          </span>
          {!!isLoading && <Spinner size={20} />}
        </HeaderTitle>
      </Header>

      <Tabs
        contentStyle={tabsContentStyle}
        index={props.tab === 'moderation' ? 1 : 0}
      >
        <Tabs.Item title='Metrics' onClick={handleOnClickMetricsTab}>
          {renderMetricsTab()}
        </Tabs.Item>
        {/* <Tabs.Item
          title='Moderation'
          onClick={() => navigate(`/sponsored-posts/${params.id}/moderation`)}
        >
          <SponsoredPostsModeration postId={params.id} />
        </Tabs.Item> */}
        <Tabs.Item title='Edit' onClick={handleOnClickEditTab}></Tabs.Item>
        <Tabs.ExtraContent />
      </Tabs>
    </ContentContainer>
  )
}

export default SponsoredPostsDetail

const tabsContentStyle = {
  padding: '20px 0',
}

const customTableStyles = {
  headCells: {
    style: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
  cells: {
    style: {},
  },
}

const StyledSelect = styled(Select)`
  button {
    min-width: 200px;
  }
`

const StyledWell = styled(Well)`
  div {
    padding-bottom: 4px;
  }
`
