import { useCallback, useEffect, useMemo } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import {
  Avatar,
  BrandProduct,
  Button,
  Chevron,
  Dropdown,
  Navbar,
} from '@sportsyou/react-dom-ui'
import { Colors, checkColorValue, getProfileImage } from '@sportsyou/core'
import { RootState } from '../store/rootReducer'
import {
  clearUser,
  selectCurrentUser,
  setUserLoggedIn,
  updateUser,
} from '../store/slices'
import { environment } from '../environments/environment'
import { Profile, User, queryActiveFeatures, queryUser } from '@sportsyou/api'
import { useFetchApi } from '@sportsyou/react-hooks'
import useContentPortal, { ContentPortalId } from '../UseContentPortal'
import usePagesPortal from '../pages/Pages/UsePagesPortal'

interface MainLayoutProps {
  backgroundColor?: string
  portalId?: ContentPortalId
  portalName?: string
}

const buttonTextStyle = {
  alignItems: 'center',
  display: 'flex',
}

export const MainLayout = (props: MainLayoutProps) => {
  const { portalId } = props

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const { user, isUserLoggedIn } = useSelector((state: RootState) => state.user)
  const currentUser = useSelector(selectCurrentUser)

  const { page } = usePagesPortal({ communityId: params.id })

  const { fetch: getUserInfo } = useFetchApi(queryUser)
  const { fetch: getFeatures } = useFetchApi(queryActiveFeatures)

  const {
    canAccessPortal,
    fetchData: fetchPortalAccess,
    isLoading: isLoadingPortalAccess,
    portalAccess,
  } = useContentPortal()

  const validateUser = useCallback(async () => {
    // Validate user is logged in
    const { data: _user, ok } = await getUserInfo()
    if (!ok) {
      // User is not logged in, so log out
      dispatch(clearUser())
      localStorage.setItem(
        'redirect',
        `${window.location.pathname}${window.location.search}`
      )
      navigate('/login')
      return
    }

    // Update local user data
    if (!isUserLoggedIn || !currentUser.id) {
      const { data: _features } = await getFeatures()
      localStorage.setItem('user-features', JSON.stringify(_features))
      dispatch(updateUser(_user as User))
      dispatch(setUserLoggedIn(true))
      fetchPortalAccess()
    }

    // Validate permission to use the Portal
    if (!canAccessPortal(portalId)) {
      navigate('/home')
    }
  }, [
    canAccessPortal,
    currentUser?.id,
    dispatch,
    getFeatures,
    getUserInfo,
    isUserLoggedIn,
    navigate,
    portalId,
  ])

  const handleOnClickLogOut = useCallback(() => {
    navigate('/logout')
  }, [navigate])

  const handleOnClickGoToCampaigns = useCallback(() => {
    navigate('/campaigns')
  }, [navigate])

  const handleOnClickGoToSponsoredPosts = useCallback(() => {
    navigate('/sponsored-posts')
  }, [navigate])

  const handleOnClickGoToPages = useCallback(() => {
    navigate('/pages')
  }, [navigate])

  const handleOnClickGoToCommunityPage = useCallback(() => {
    window.location.href = `${environment.urls.web}/pages/${
      page?.communityUrl ?? ''
    }`
  }, [page?.communityUrl])

  const handleOnClickNavbarBrand = useCallback(() => {
    if (portalId === 'pages') {
      handleOnClickGoToCommunityPage()
    } else if (portalId === 'campaigns') {
      handleOnClickGoToCampaigns()
    } else if (portalId === 'sponsoredPosts') {
      handleOnClickGoToSponsoredPosts()
    }
  }, [
    handleOnClickGoToCampaigns,
    handleOnClickGoToCommunityPage,
    handleOnClickGoToSponsoredPosts,
    portalId,
  ])

  const logoUrl = useMemo(() => {
    if (!page) return undefined
    return (
      getProfileImage.getProfileAvatarImageUrl(page as Profile) ?? undefined
    )
  }, [page])

  const userAvatarUrl = useMemo(() => {
    return (
      getProfileImage.getProfileAvatarImageUrl(user as Profile) ?? undefined
    )
  }, [user])

  const theme = useMemo(() => {
    const backgroundColor = page?.color ?? props.backgroundColor
    if (backgroundColor) {
      return {
        backgroundColor,
        color: checkColorValue(backgroundColor),
      }
    } else {
      return {
        backgroundColor: Colors.PUNCH,
        color: Colors.WHITE,
      }
    }
  }, [page?.color, props.backgroundColor])

  useEffect(() => {
    if (!isLoadingPortalAccess) {
      validateUser()
    }
  }, [isLoadingPortalAccess])

  const productData = useMemo(() => {
    return {
      name:
        (page?.name ?? props.portalName ?? 'Content Portal') +
        ` – ${environment.env}`,
    } as BrandProduct
  }, [page?.name, props.portalName])

  const preBrandContent = useMemo(() => {
    if (logoUrl) {
      return (
        <img
          alt={page?.name as string}
          src={logoUrl}
          style={{
            border: '1px solid #fff',
            borderRadius: 20,
            height: 40,
            marginRight: 10,
          }}
        />
      )
    } else {
      return undefined
    }
  }, [logoUrl, page?.name])

  return (
    <Container>
      <Navbar backgroundColor={theme?.backgroundColor} color={theme?.color}>
        <Navbar.Left
          brand
          onClickBrand={handleOnClickNavbarBrand}
          product={productData}
          preBrandContent={preBrandContent}
        />
        <Navbar.Right>
          {isUserLoggedIn && (
            <>
              {portalId === 'pages' && (
                <PageLinkButton
                  appearance='ghost'
                  collapse
                  color={theme.color}
                  onClick={handleOnClickGoToCommunityPage}
                  textStyle={buttonTextStyle}
                  useWhiteBase
                  variant='alternate'
                >
                  Go To {page?.communityUrl ? 'Page' : 'Pages'}
                </PageLinkButton>
              )}
              <Dropdown placement='bottomEnd'>
                <Dropdown.Toggle>
                  <Button
                    appearance='minimal'
                    collapse
                    color={theme.color}
                    textStyle={buttonTextStyle}
                    useWhiteBase
                    variant='alternate'
                  >
                    {userAvatarUrl || user?.fullName ? (
                      <Avatar
                        diameter={20}
                        name={user.fullName as string}
                        uri={userAvatarUrl}
                      />
                    ) : null}
                    <Username color={theme.color}>{user.fullName}</Username>
                    <StyledChevron fill={theme.color} size={18} />
                  </Button>
                </Dropdown.Toggle>
                {portalAccess.sponsoredPosts ? (
                  <Dropdown.Item onClick={handleOnClickGoToSponsoredPosts}>
                    Sponsored Posts
                  </Dropdown.Item>
                ) : null}
                {portalAccess.pages ? (
                  <Dropdown.Item onClick={handleOnClickGoToPages}>
                    Pages
                  </Dropdown.Item>
                ) : null}
                {portalAccess.campaigns ? (
                  <Dropdown.Item onClick={handleOnClickGoToCampaigns}>
                    Campaigns
                  </Dropdown.Item>
                ) : null}
                <Dropdown.Divider gap={0} />
                <Dropdown.Item onClick={handleOnClickLogOut}>
                  Log Out
                </Dropdown.Item>
              </Dropdown>
            </>
          )}
        </Navbar.Right>
      </Navbar>
      <Content>
        <Outlet />
      </Content>
    </Container>
  )
}

export default MainLayout

const Container = styled.div`
  background-color: ${Colors.ALTO};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Content = styled.div`
  align-self: center;
  max-width: 1200px;
  width: 100%;
`

const PageLinkButton = styled(Button)`
  border-color: ${({ color }) => color} !important;
  color: ${({ color }) => color} !important;
  margin-right: 10px;
  padding: 0 20px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }
`

const Username = styled.span<{ color?: string }>`
  margin-left: 10px;
  color: ${({ color }) => color};
`

const StyledChevron = styled(Chevron)`
  margin-left: 10px;
`
