import { CSSProperties, useCallback } from 'react'

import Avatar from '../avatar'
import { AvatarDataTypes, AvatarGroupItemProps } from '../avatar/helpers'
import styled, { css } from 'styled-components'

interface AvatarGridPropTypes {
  data: AvatarDataTypes[]
  backgroundColor?: string
  borderRadius?: number
  className?: string
  items: Array<AvatarGroupItemProps>
  diameter?: number
  hideBackground?: boolean
  names?: Array<string>
  layout?: 'grid' | 'stack'
  stackDirection?: 'horizontal' | 'vertical' | 'diagonal'
  testId?: string
}

const AvatarGroupGrid: React.FC<AvatarGridPropTypes> = ({
  backgroundColor = '#d7d8e1',
  className,
  data,
  borderRadius,
  hideBackground = false,
  diameter = 60,
  names = [],
  testId,
}: AvatarGridPropTypes) => {
  const adjustedDiameter = diameter / 2 - 4

  const setAvatarPosition = useCallback(
    (index: number): CSSProperties => {
      const total = names.length
      let styles: CSSProperties = {}
      const sharedStyles: CSSProperties = {
        flex: `0 0 ${adjustedDiameter}px`,
        position: total === 4 ? 'relative' : 'absolute',
      }
      const spaceFromEdge = diameter / 2 - adjustedDiameter - 1

      if (total === 2) {
        if (index === 0) {
          styles = {
            left: spaceFromEdge,
            top: spaceFromEdge,
          }
        } else if (index === 1) {
          styles = {
            bottom: spaceFromEdge,
            right: spaceFromEdge,
          }
        }
      } else if (total === 3) {
        if (index === 0) {
          styles = {
            left: spaceFromEdge,
            top: spaceFromEdge,
          }
        } else if (index === 1) {
          styles = {
            right: spaceFromEdge,
            top: spaceFromEdge,
          }
        } else if (index === 2) {
          styles = {
            bottom: spaceFromEdge,
            left: spaceFromEdge,
          }
        }
      } else if (total >= 4) {
        if (index === 0) {
          styles = {
            position: 'absolute',
            left: spaceFromEdge,
            top: spaceFromEdge,
          }
        } else if (index === 1) {
          styles = {
            position: 'absolute',
            right: spaceFromEdge,
            top: spaceFromEdge,
          }
        } else if (index === 2) {
          styles = {
            left: spaceFromEdge,
            bottom: spaceFromEdge,
            position: 'absolute',
          }
        } else if (index === 3) {
          styles = {
            right: spaceFromEdge,
            bottom: spaceFromEdge,
            position: 'absolute',
          }
        }
        styles = {
          ...styles,
          // flexDirection: 'row' : 'column',
          display: 'flex',
          flexWrap: 'wrap',
        }
      }

      return {
        ...sharedStyles,
        ...styles,
      }
    },
    [adjustedDiameter, diameter, names.length]
  )

  return (
    <Container
      $borderRadius={borderRadius}
      $backgroundColor={backgroundColor}
      $hideBackground={hideBackground}
      $size={diameter}
      $useFlex={names.length >= 4}
      className={className}
      data-testid={testId}
    >
      {data.length
        ? data.map((item, index) => (
            <Avatar
              borderRadius={borderRadius}
              diameter={data.length === 2 ? diameter / 2 : adjustedDiameter}
              // fontSize={12}
              key={index}
              name={item.display}
              uri={item.uri}
              useRawName={item.useRawString}
              // TODO: use styled-components to set position
              style={setAvatarPosition(index)}
              title={item.title}
            />
          ))
        : null}
    </Container>
  )
}

const Container = styled.div<{
  $backgroundColor: string
  $borderRadius?: number
  $hideBackground: boolean
  $size: number
  $useFlex: boolean
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${({ $borderRadius, $hideBackground, $size }) =>
    $borderRadius ? $borderRadius : $hideBackground ? 0 : $size / 4}px;
  box-sizing: border-box;
  height: ${({ $size }) => $size}px;
  width: ${({ $size }) => $size}px;
  justify-content: space-between;
  position: relative;
  ${({ $useFlex }) =>
    $useFlex
      ? css`
          display: flex;
          flex-wrap: wrap;
        `
      : undefined};
`

export default AvatarGroupGrid
