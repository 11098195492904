import React, { MouseEvent, useCallback, useEffect, useState } from 'react'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import styled from 'styled-components'

import { Colors, isFile } from '@sportsyou/core'
import {
  TranscodingTypes,
  UploadType,
  useFetchApi,
  useUploader,
  ExtendedFile,
  ExtendedUpload,
} from '@sportsyou/react-hooks'
import { X } from '@sportsyou/react-icons'
import { mutationUploadDelete, queryUploadUrl } from '@sportsyou/api'

import { useDialog } from '../../hooks'

import Button from '../button'
import FileTypeIcon from '../file-type-icon'
import ImageCropper, { ImageCropperProps } from '../image-cropper'
import Progress from '../progress'
import Spinner from '../spinner'

type TestIds = {
  container?: string
}

export interface FileInputProps {
  acceptedFileTypes?: string
  className?: string
  cropConfig?: ImageCropperProps
  hideSelectFileButton?: boolean
  label?: string
  maxFiles?: number
  maxSize?: number
  onlySelectFileButton?: boolean
  onPressThumbnail?: (
    uploads?: ExtendedUpload[],
    index?: number,
    upload?: ExtendedUpload
  ) => void
  onTranscodeComplete?: (upload?: ExtendedUpload) => void
  onUploaderCancel?: (upload?: ExtendedUpload) => void
  onUploaderError?: (
    uploads: ExtendedUpload[],
    currentUpload: ExtendedUpload
  ) => void
  onUploaderUploadComplete?: (
    uploads: ExtendedUpload[],
    currentUpload: ExtendedUpload
  ) => void
  onUploaderUploadDone?: (
    uploads: ExtendedUpload[],
    currentUpload: ExtendedUpload
  ) => void
  onUploaderUploadProgress?: (
    uploads: ExtendedUpload[],
    currentUpload: ExtendedUpload
  ) => void
  onUploaderUploadStart?: (
    uploads: ExtendedUpload[],
    currentUpload: ExtendedUpload
  ) => void
  selectFileButton?: React.ReactNode
  selectFileButtonLabel?: string
  selectFileButtonStyle?: any
  selectFileButtonVariant?: 'primary' | 'secondary' | 'alternate'
  setIsBusy?: (isBusy: boolean) => void
  shouldCreateHLS?: boolean
  skipWaitForTranscoding?: boolean
  style?: React.CSSProperties
  testId?: TestIds
  transcodings?: TranscodingTypes
  uploads?: ExtendedUpload[] // Completed uploads
  uploadType?: UploadType
  uploadsContainerStyle?: React.CSSProperties
}

export default function FileInput(props: FileInputProps) {
  const uploader = useUploader.useUploader()
  const { sendBanner } = useDialog()

  const { fetch: getUploadUrl } = useFetchApi(queryUploadUrl)
  const { fetch: removeMedia } = useFetchApi(mutationUploadDelete)

  const [cropConfig, setCropConfig] = useState<ImageCropperProps | undefined>(
    props.cropConfig
  )
  // Uploads that are in progress, managed within this component
  const [uploadsInProgress, setUploadsInProgress] = useState<ExtendedUpload[]>(
    []
  )

  useEffect(() => {
    return () => {
      const inProgress = uploader.getUploadsInProgress()
      if (inProgress.length) {
        for (const upload of inProgress) {
          upload.id && uploader.cancel(upload.id)
        }
        uploader.reset()
      }
    }
  }, [])

  const getTranscodeUrl = (
    upload?: ExtendedUpload,
    transcodeTypes: string[] = []
  ) => {
    let url = ''
    if (upload?.transcodes?.length) {
      const transcode = upload.transcodes
        .filter(
          (transcode) =>
            transcode?.transcodeType &&
            transcodeTypes.includes(transcode.transcodeType)
        )
        .shift()
      if (transcode?.viewUrl) {
        url = transcode.viewUrl
      }
    }
    return url
  }

  const onRemoveUpload = useCallback(
    (e: React.MouseEvent, index: number, upload?: ExtendedUpload) => {
      e.preventDefault()
      e.stopPropagation()
      upload?.id && uploader.cancel(upload.id)
      props.onUploaderCancel?.(upload)
    },
    [uploadsInProgress, uploader]
  )

  const onDropFileAccepted = useCallback(
    async (files: ExtendedFile[]) => {
      if (props.cropConfig) {
        setCropConfig({ ...props.cropConfig, inputFile: files[0] })
      } else {
        // prevent adding more uploads while there are uploads in progress
        if (uploadsInProgress.length) {
          sendBanner({
            autoDismiss: true,
            status: 'alert',
            message:
              'Please wait until current uploads are done and try again.',
          })
        } else {
          upload(files)
        }
      }
    },
    [props.cropConfig, uploadsInProgress]
  )

  async function upload(files: ExtendedFile[]): Promise<void> {
    uploader.reset()
    await uploader.create({
      files,
      onCancel: onUploaderCancel,
      onComplete: onUploaderComplete,
      onError: onUploaderError,
      onProgress: onUploaderProgress,
      onUploadDone: onUploaderUploadDone,
      onUploadStart: onUploaderUploadStart,
      shouldCreateHLS: props.shouldCreateHLS,
      skipWaitForTranscoding: props.skipWaitForTranscoding,
      transcodings: props.transcodings,
      uploadType: props.uploadType,
    })
  }

  const onUploaderUploadStart = useCallback(
    (uploads: ExtendedUpload[], currentUpload: ExtendedUpload) => {
      props.onUploaderUploadStart?.(uploads, currentUpload)

      // filter out only uploads in progress
      const uploadIdsToFilterOut = props.uploads?.map((up) => up.id) ?? []
      const uploadsInProgress = uploader
        .getUploadsInProgress()
        .filter((up) => !uploadIdsToFilterOut?.includes(up.id))
      console.log('onUploaderUploadStart', { uploadsInProgress, currentUpload })
      setUploadsInProgress(uploadsInProgress)
      props.setIsBusy?.(true)
    },
    [
      uploader.getUploadsInProgress,
      props.setIsBusy,
      props.uploads,
      props.onUploaderUploadStart,
    ]
  )

  const onUploaderProgress = useCallback(
    (uploads: ExtendedUpload[], currentUpload: ExtendedUpload) => {
      props.onUploaderUploadProgress?.(uploads, currentUpload)

      // filter out only uploads in progress
      const uploadIdsToFilterOut = props.uploads?.map((up) => up.id) ?? []
      const uploadsInProgress = uploader
        .getUploadsInProgress()
        .filter((up) => !uploadIdsToFilterOut?.includes(up.id))
      console.log('onUploaderProgress', { uploadsInProgress, currentUpload })
      setUploadsInProgress(uploadsInProgress)
    },
    [
      uploader.getUploadsInProgress,
      props.uploads,
      props.onUploaderUploadProgress,
    ]
  )

  const onUploaderUploadDone = useCallback(
    async (uploads: ExtendedUpload[], currentUpload: ExtendedUpload) => {
      // fetch upload detail from server side to return completed upload
      const { data: _upload } = (await getUploadUrl({
        uploadId: currentUpload.id,
      })) as { data: ExtendedUpload }
      props.onUploaderUploadDone?.(uploads, _upload)
      !_upload.uploaderProps?.skipWaitForTranscoding &&
        props.onTranscodeComplete?.(_upload)

      // filter out only uploads in progress
      const uploadIdsToFilterOut = props.uploads?.map((up) => up.id) ?? []
      const uploadsInProgress = uploader
        .getUploadsInProgress()
        .filter((up) => !uploadIdsToFilterOut?.includes(up.id))
      console.log('onUploaderUploadDone', { uploadsInProgress, _upload })
      setUploadsInProgress(uploadsInProgress)
    },
    [
      uploader.getUploadsInProgress,
      props.uploads,
      props.onUploaderUploadDone,
      props.onTranscodeComplete,
    ]
  )

  const onUploaderError = useCallback(
    async (uploads: ExtendedUpload[], cancelledUpload: ExtendedUpload) => {
      console.log('onUploaderError', {
        uploads,
        currentUpload: cancelledUpload,
      })

      const { progress } = cancelledUpload
      const failedStep = progress?.clientProgress !== 100 ? 'upload' : 'process'
      await removeMedia({ id: cancelledUpload.id })
      sendBanner({
        autoDismiss: false,
        status: 'alert',
        message: `Item "${
          cancelledUpload.fileName ?? ''
        }" failed to ${failedStep}.`,
      })

      props.onUploaderError?.(uploads, cancelledUpload)
    },
    [props.onUploaderError]
  )

  const onUploaderCancel = useCallback(
    (uploads: ExtendedUpload[], currentUpload?: ExtendedUpload) => {
      console.log('onUploaderCancel', { uploads, currentUpload })
      props.onUploaderCancel?.(currentUpload)
    },
    [props.onUploaderCancel]
  )

  const onUploaderComplete = useCallback(
    (uploads: ExtendedUpload[], currentUpload: ExtendedUpload) => {
      console.log('onUploaderComplete', uploads, currentUpload)
      if (uploader.checkAllComplete()) {
        setUploadsInProgress([])
        props.onUploaderUploadComplete?.(uploads, currentUpload)
        props.setIsBusy?.(false)
      }
    },
    [uploader.checkAllComplete, props.setIsBusy]
  )

  const objectUrlToFile = (objectUrl: string, filename: string) => {
    return fetch(objectUrl)
      .then((res) => res.blob())
      .then((blob) => new File([blob], filename, { type: blob.type }))
  }

  const onCrop = useCallback(
    async (dataUrl: string) => {
      const file = await objectUrlToFile(dataUrl, 'cropped.jpg')
      upload([file])
      setCropConfig(undefined)
    },
    [upload]
  )

  const onCropCancel = useCallback(() => {
    setCropConfig(undefined)
  }, [])

  const onDropFileRejected = useCallback(
    (fileRejections: FileRejection[], event: DropEvent) => {
      console.log({ fileRejections, event })
    },
    []
  )

  function handleOnPressThumbnail(
    event: MouseEvent<HTMLDivElement>,
    index: number,
    upload: ExtendedUpload,
    uploads: ExtendedUpload[]
  ) {
    if (props.onPressThumbnail) {
      event.preventDefault()
      event.stopPropagation()
      props.onPressThumbnail(uploads, index, upload)
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: props.acceptedFileTypes,
    maxFiles: props.maxFiles,
    maxSize: props.maxSize ?? 50 * 1024 * 1024 * 1024, // 50GB
    onDropAccepted: onDropFileAccepted,
    onDropRejected: onDropFileRejected,
    useFsAccessApi: false,
  })

  function renderSelectFileButton() {
    if (props.hideSelectFileButton) {
      return null
    }
    if (props.selectFileButton) {
      return props.selectFileButton
    }
    return (
      <Button
        variant={props.selectFileButtonVariant ?? 'alternate'}
        style={props.selectFileButtonStyle}
      >
        {props.selectFileButtonLabel ?? 'Select File'}
      </Button>
    )
  }

  return (
    <>
      {props.onlySelectFileButton ? (
        <span
          {...getRootProps({
            className: 'dropzone',
            role: 'button',
          })}
        >
          <input {...getInputProps()} />
          {renderSelectFileButton()}
        </span>
      ) : (
        <InputContainer
          data-testid={props.testId?.container}
          style={props.style}
          {...getRootProps({
            className: props.className
              ? `${props.className} dropzone`
              : 'dropzone',
            role: 'button',
            disabled: !!cropConfig,
          })}
        >
          {!!props.label && (
            <InputContainerLabel>{props.label}</InputContainerLabel>
          )}
          <input {...getInputProps()} />
          {!(uploadsInProgress?.length || props.uploads?.length) && (
            <UploadsContainer style={props.uploadsContainerStyle}>
              <EmptyText>
                {isDragActive
                  ? 'Drop the files here ...'
                  : 'Drag and drop your file here, or click to select'}
              </EmptyText>
            </UploadsContainer>
          )}

          {!props.onlySelectFileButton &&
            !!(props.uploads?.length || uploadsInProgress?.length) && (
              <UploadsContainer style={props.uploadsContainerStyle}>
                {props.uploads?.map((upload, index, uploads) => {
                  const uploadSrc = getTranscodeUrl(upload, [
                    'feed',
                    'media',
                    'THUMB',
                  ])
                  return (
                    <UploadThumbContainer
                      key={upload?.id}
                      onClick={(e: MouseEvent<HTMLDivElement>) =>
                        handleOnPressThumbnail(e, index, upload, uploads)
                      }
                    >
                      <ThumbX
                        fill={Colors.WHITE}
                        height={24}
                        onClick={(e: React.MouseEvent<SVGSVGElement>) =>
                          onRemoveUpload(e, index, upload!)
                        }
                        width={24}
                      />
                      {isFile(upload.contentType!) ? (
                        <UploadThumbFile>
                          <FileTypeIcon
                            contentType={upload.contentType!}
                            fileName={upload.fileName!}
                            nameCase='pascal'
                            size={40}
                          />
                          <span>{upload.fileName}</span>
                        </UploadThumbFile>
                      ) : (
                        <>
                          {!uploadSrc && <Spinner fill={Colors.WHITE} />}
                          {!!uploadSrc && <UploadThumbMedia src={uploadSrc} />}
                        </>
                      )}
                    </UploadThumbContainer>
                  )
                })}
                {uploadsInProgress?.map((upload) => {
                  const uploadSrc =
                    /^image\/(jp?eg|png|gif|webp)/.test(
                      upload.contentType ?? ''
                    ) && window.URL.createObjectURL(upload.file as Blob)
                  return (
                    <UploadThumbContainer key={upload.id}>
                      {!!uploadSrc && <UploadThumbMedia src={uploadSrc} />}
                      <InfiniteSpinnerContainer>
                        <Spinner fill={Colors.WHITE} />
                      </InfiniteSpinnerContainer>
                      <UploadThumbProgress>
                        <Progress
                          fill={Colors.WHITE}
                          value={upload.progress?.clientProgress}
                        />
                      </UploadThumbProgress>
                    </UploadThumbContainer>
                  )
                })}
              </UploadsContainer>
            )}
          {renderSelectFileButton()}
        </InputContainer>
      )}
      {!!cropConfig && (
        <ImageCropper {...cropConfig} onCrop={onCrop} onClose={onCropCancel} />
      )}
    </>
  )
}

const InputContainer = styled.div`
  border: 1px solid #dddddd;
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 10px;
`

const InputContainerLabel = styled.div`
  align-items: center;
  color: ${Colors.PUNCH};
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  text-transform: uppercase;
`

const UploadsContainer = styled.div`
  border-radius: 6px;
  border: 1px dashed #dddddd;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;
  max-height: 1000px;
  overflow-x: auto;
  padding: 10px;
  padding-bottom: 0;
`

const EmptyText = styled.div`
  color: ${Colors.SHUTTLE_GRAY};
  padding: 40px 10px;
`

const UploadThumbContainer = styled.div`
  align-items: center;
  background-color: ${Colors.MINE_SHAFT};
  border-radius: 6px;
  border: 1px solid ${Colors.ALTO};
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  width: 100px;

  img {
    min-height: fit-content;
    min-width: fit-content;
    object-fit: contain;
  }
`

const UploadThumbProgress = styled.div`
  bottom: 0;
  position: absolute;
  width: 100px;
`
const ThumbX = styled(X)`
  background-color: ${Colors.HAVELOCK_BLUE};
  border-radius: 50%;
  color: ${Colors.WHITE};
  cursor: pointer;
  font-size: 12px;
  opacity: 0.8;
  padding: 5px;
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 1;

  &:hover {
    opacity: 1;
  }
`
const UploadThumbMedia = styled.img`
  max-height: 100px;
  max-width: 100px;
`

const UploadThumbFile = styled.div`
  align-items: center;
  background-color: ${Colors.WHITE};
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100px;

  svg {
    margin-bottom: 10px;
  }

  span {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    font-size: 12px;
    max-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const InfiniteSpinnerContainer = styled.div`
  position: absolute;
`
