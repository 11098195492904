import { inRange } from 'lodash'

import {
  US_PHONE_NUMBER_LENGTH,
  US_PHONE_NUMBER_WITH_COUNTRY_CODE_LENGTH,
} from '../constants'

// interface PhoneNumberValidationResultInterface {
//   phoneNumber: string
//   valid: boolean
// }

// export const isPhoneNumberValid = (
//   phoneNumber: string
// ): PhoneNumberValidationResultInterface => {
//   // make sure none of the characters outside of allowed (commonly used) characters
//   // numeric digits, dot[.], dash[-], parenthesis[()], plus[+], space[ ], star/asterisk[*], sharp/pound sign[#]
//   // const invalidCharactersMatch = phoneNumber.match(/[^\d\.\-\(\)\s\+\*\#]/g)
//   const invalidCharactersMatch = phoneNumber.match(/[^\d.\-(\\s+*#]/g)

//   const numberMatch = phoneNumber.replace(/\D/g, '')
//   const ret: PhoneNumberValidationResultInterface = {
//     phoneNumber: '',
//     valid: false,
//   }

//   if (
//     phoneNumber.length &&
//     (!invalidCharactersMatch || !invalidCharactersMatch.length)
//   ) {
//     if (
//       !!numberMatch &&
//       inRange(
//         numberMatch.length,
//         US_PHONE_NUMBER_LENGTH,
//         US_PHONE_NUMBER_WITH_COUNTRY_CODE_LENGTH + 1
//       )
//     ) {
//       if (
//         (numberMatch.substring(0, 1) === '1' &&
//           numberMatch.length === US_PHONE_NUMBER_WITH_COUNTRY_CODE_LENGTH) ||
//         numberMatch.length === US_PHONE_NUMBER_LENGTH
//       ) {
//         ret.valid = true
//         ret.phoneNumber = numberMatch.slice(-US_PHONE_NUMBER_LENGTH) // return phone number portion only
//       }
//     }
//   }

//   return ret
// }

type PhoneNumberValiadtionResults = {
  phoneNumber: string
  sanitizedPhoneNumber: string
  valid: boolean
}

/**
 * Validate a US phone number
 * @param phoneNumber - phone number to validate
 * @returns - validation results
 * @example
 * const phoneNumber = '123-456-7890'
 * const validationResults = validatePhoneNumber(phoneNumber)
 * console.log(validationResults.valid) // true
 */
export const isPhoneNumberValid = (
  phoneNumber: string
): PhoneNumberValiadtionResults => {
  let valid = false
  // sanitize phone number with only numeric digits
  let sanitizedPhoneNumber = phoneNumber
    // .replace(/[()\-+ ]/g, '')
    .replace(/[^0-9]/g, '')

  // make sure the number is within the range of US phone number length
  if (
    inRange(
      sanitizedPhoneNumber.length,
      US_PHONE_NUMBER_LENGTH,
      US_PHONE_NUMBER_WITH_COUNTRY_CODE_LENGTH + 1
    )
  ) {
    valid = true
  }

  // if the number is 11 digits long, it must start with 1 (us country code)
  if (
    sanitizedPhoneNumber.length === US_PHONE_NUMBER_WITH_COUNTRY_CODE_LENGTH
  ) {
    valid = sanitizedPhoneNumber.substring(0, 1) === '1'
    // remove the country code, we currently only support US phone numbers
    if (sanitizedPhoneNumber.startsWith('1')) {
      sanitizedPhoneNumber = sanitizedPhoneNumber.slice(-US_PHONE_NUMBER_LENGTH)
    }
  }

  return {
    phoneNumber,
    sanitizedPhoneNumber,
    valid,
  }
}
