import { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import {
  Campaign,
  CampaignDayStats,
  CampaignStats,
  queryCampaignStats,
} from '@sportsyou/api'
import { arrayToCVS, Colors, exportCSV, readableNumber } from '@sportsyou/core'
import {
  Button,
  CopyToClipboard,
  DateRange,
  FadeInContainer,
  Modal,
  Skeleton,
  Spinner,
  Well,
} from '@sportsyou/react-dom-ui'
import { useFetchApi } from '@sportsyou/react-hooks'

import { screenLocationOptions } from '../CampaignForm/CampaignFormAssets'
import ActivityDirectionIcon from './Metrics/ActivityDirectionIcon'
import ActivityGraph from './Metrics/ActivityGraph'
import LocationIcon from '../CampaignForm/LocationIcon'

interface CampaignMetricsTabProps {
  campaign: Campaign
}

const graphColors = [Colors.PUNCH, Colors.DUSTY_GRAY]
const graphKeys = ['views', 'clicks']

export default function CampaignMetricsTab(props: CampaignMetricsTabProps) {
  const { campaign } = props

  const [endDate, setEndDate] = useState<Date>(() =>
    moment(campaign.endDate ?? moment().add(1, 'year')).toDate()
  )
  const [isDownloadOptionsVisible, setIsDownloadOptionsVisible] =
    useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState<Date>(() =>
    moment(campaign.startDate ?? moment().subtract(1, 'year')).toDate()
  )
  const [stats, setStats] = useState<CampaignStats>()

  const { fetch: getCampaignStats } = useFetchApi(queryCampaignStats)

  const fetchStats = useCallback(async () => {
    if (campaign?.id) {
      const { data: _stats, error } = await getCampaignStats({
        campaignId: campaign.id,
      })
      if (!error) {
        setStats(_stats)
        setIsLoading(false)
      }
    }
  }, [getCampaignStats, campaign.id])

  const generateCSVData = useCallback(() => {
    const dailyStats = (stats?.dailyActivity as CampaignDayStats[]) ?? []
    const filteredStats = dailyStats.filter((stat) => {
      const logDate = moment(stat.logDate)
      return logDate.isBetween(startDate, endDate, 'day', '[]')
    })
    return (
      filteredStats.map((stat) => {
        return {
          Date: stat.logDate,
          Views: stat.viewCount ?? 0,
          Clicks: stat.clickCount ?? 0,
        }
      }) ?? []
    )
  }, [endDate, startDate, stats?.dailyActivity])

  const handleOnClickDownloadOptions = useCallback(() => {
    setIsDownloadOptionsVisible((prev) => !prev)
  }, [])

  const handleOnClickCopyCSV = useCallback(() => {
    return arrayToCVS(generateCSVData())
  }, [generateCSVData])

  const handleOnClickDownloadCSV = useCallback(() => {
    const fileName =
      campaign.campaignName?.slice(0, 20).replace(/\W/g, '-') ?? 'campaign'
    const date = moment().format('YYYY-MM-DD')
    exportCSV(generateCSVData(), `${fileName}_${date}.csv`)
  }, [campaign.campaignName, generateCSVData])

  const viewsToday = useMemo(() => {
    if (!stats?.dailyActivity) {
      return undefined
    }
    const todayStats = stats?.dailyActivity?.find((value) => {
      return moment(value?.logDate).isSame(moment(), 'day')
    })
    return todayStats?.viewCount ?? 0
  }, [stats])

  const clicksToday = useMemo(() => {
    if (!stats?.dailyActivity) {
      return undefined
    }
    const todayStats = stats?.dailyActivity?.find((value) => {
      return moment(value?.logDate).isSame(moment(), 'day')
    })
    return todayStats?.clickCount ?? 0
  }, [stats])

  const viewsYesterday = useMemo(() => {
    const yesterdayStats = stats?.dailyActivity?.find((value) => {
      return moment(value?.logDate).isSame(moment().subtract(1, 'day'), 'day')
    })
    return yesterdayStats?.viewCount ?? 0
  }, [stats])

  const clicksYesterday = useMemo(() => {
    const yesterdayStats = stats?.dailyActivity?.find((value) => {
      return moment(value?.logDate).isSame(moment().subtract(1, 'day'), 'day')
    })
    return yesterdayStats?.clickCount ?? 0
  }, [stats])

  const activityGraphDataDaily = useMemo(() => {
    const activities = [...(stats?.dailyActivity ?? [])].sort((a, b) => {
      return moment(a?.logDate).diff(moment(b?.logDate))
    })
    // show only the most recent activities on the daily graph
    const length = activities.length - 7 > 0 ? activities.length - 7 : 0
    return (
      activities.slice(length).map((value, index) => {
        return {
          name: moment(value?.logDate).format('MM/DD'),
          views: value?.viewCount as number,
          clicks: value?.clickCount as number,
        }
      }) ?? []
    )
  }, [stats])

  const activityGraphDataMonthly = useMemo(() => {
    const activities = [...(stats?.monthlyActivity ?? [])].sort((a, b) => {
      return moment(a?.logDate).diff(moment(b?.logDate))
    })
    return (
      activities.map((value, index) => {
        return {
          name: moment(value?.logDate).format('MMM').toUpperCase(),
          views: value?.viewCount as number,
          clicks: value?.clickCount as number,
        }
      }) ?? []
    )
  }, [stats])

  const percentClicksToViews = useMemo(() => {
    const totalViews = stats?.totalViewCount ?? 0
    const totalClicks = stats?.totalClickCount ?? 0
    if (totalViews > 0 && totalClicks > 0) {
      const percent = totalClicks / totalViews
      // percent to 2 decimal places, removing trailing zeros
      return ((percent ?? 0) * 100).toFixed(2).replace(/\.?0+$/, '')
    } else {
      return 0
    }
  }, [stats])

  useEffect(() => {
    fetchStats()
  }, [fetchStats])

  return (
    <MetricsBlocks>
      <Section>
        <SectionTitle>
          Total
          <DownloadsButton onClick={handleOnClickDownloadOptions}>
            Download daily data sheets
          </DownloadsButton>
        </SectionTitle>
        <TotalsLargeContainer>
          <TotalsLarge>
            <div>
              <label>
                <strong>Views</strong>
              </label>
              <TotalsLargeValue>
                <Skeleton
                  barHeight={22}
                  width={40}
                  ready={stats?.totalViewCount !== undefined}
                >
                  <FadeInContainer>
                    {readableNumber(stats?.totalViewCount as number)}
                  </FadeInContainer>
                </Skeleton>
              </TotalsLargeValue>
            </div>
            <div>
              <label>Today</label>
              <Skeleton
                barHeight={11}
                width={35}
                ready={viewsToday !== undefined}
              >
                <FadeInContainer>
                  <TotalContainer>
                    <ActivityDirectionIcon
                      currentValue={viewsToday}
                      previousValue={viewsYesterday}
                    />
                    {readableNumber(viewsToday)}
                  </TotalContainer>
                </FadeInContainer>
              </Skeleton>
            </div>
          </TotalsLarge>

          <TotalsLarge>
            <div>
              <label>
                <strong>Clicks</strong>
              </label>
              <TotalsLargeValue>
                <Skeleton
                  barHeight={22}
                  width={100}
                  ready={stats?.totalClickCount !== undefined}
                >
                  <FadeInContainer>
                    {readableNumber(stats?.totalClickCount as number)}
                    <span>{percentClicksToViews}% of views</span>
                  </FadeInContainer>
                </Skeleton>
              </TotalsLargeValue>
            </div>
            <div>
              <label>Today</label>
              <Skeleton
                barHeight={11}
                width={35}
                ready={clicksToday !== undefined}
              >
                <FadeInContainer>
                  <TotalContainer>
                    <ActivityDirectionIcon
                      currentValue={clicksToday}
                      previousValue={clicksYesterday}
                    />
                    {readableNumber(clicksToday)}
                  </TotalContainer>
                </FadeInContainer>
              </Skeleton>
            </div>
          </TotalsLarge>
        </TotalsLargeContainer>
      </Section>

      <Section>
        <TotalsLargeContainer>
          <ActivityGraphContainer>
            <ActivityGraphHeader>
              <SectionTitle>
                <strong>Activity</strong>
              </SectionTitle>
              <div>Daily</div>
            </ActivityGraphHeader>
            {activityGraphDataDaily.length === 0 ? (
              <div>No data to display</div>
            ) : (
              <ActivityGraph
                colors={graphColors}
                data={activityGraphDataDaily}
                keys={graphKeys}
              />
            )}
          </ActivityGraphContainer>
          <ActivityGraphContainer>
            <ActivityGraphHeader>
              <SectionTitle>
                <strong>Activity</strong>
              </SectionTitle>
              <div>Monthly</div>
            </ActivityGraphHeader>
            {activityGraphDataMonthly.length === 0 ? (
              <div>No data to display</div>
            ) : (
              <ActivityGraph
                colors={graphColors}
                data={activityGraphDataMonthly}
                keys={graphKeys}
              />
            )}
          </ActivityGraphContainer>
        </TotalsLargeContainer>
      </Section>

      <Section>
        <SectionTitle>By Screens</SectionTitle>

        <LocationCardsContainer>
          {stats?.screenLocations?.length === 0 ? (
            <div>No data to display</div>
          ) : null}

          {screenLocationOptions.map((option) => {
            const locationStats = stats?.screenLocations?.find(
              (location) => location?.screenLocation === option.name
            )
            if (!locationStats) {
              return null
            }
            const location = campaign.screenLocations?.find(
              (location) => location?.locationName === option.name
            )
            return (
              <LocationCard key={option.name}>
                <LocationCardTitle>
                  <LocationIcon option={option} />
                  {option.label}
                </LocationCardTitle>
                <LocationCardData>
                  <LocationCardDataLabel>
                    Total Views
                    <TotalContainer>
                      {readableNumber(locationStats?.totalViewCount ?? 0)}
                    </TotalContainer>
                  </LocationCardDataLabel>
                  <LocationCardDataLabel>
                    Total Clicks
                    <TotalContainer>
                      {readableNumber(locationStats?.totalClickCount ?? 0)}
                    </TotalContainer>
                  </LocationCardDataLabel>
                  <LocationCardDataLabel>
                    Today
                    <TotalContainer>
                      {readableNumber(locationStats?.oneDayViewCount ?? 0)}
                    </TotalContainer>
                  </LocationCardDataLabel>
                  <LocationCardDataLabel>
                    Today
                    <TotalContainer>
                      {readableNumber(locationStats?.oneDayClickCount ?? 0)}
                    </TotalContainer>
                  </LocationCardDataLabel>
                </LocationCardData>
                <LocationCardDataLabel>
                  Asset
                  <LocationCardPreview>
                    <img
                      alt={option.label}
                      src={location?.assets?.[0]?.viewUrl ?? ''}
                    />
                  </LocationCardPreview>
                </LocationCardDataLabel>
                {location?.clickThroughUrl ? (
                  <LocationCardDataLabel>
                    Clickthrough
                    <LocationCardClickthrough title={location?.clickThroughUrl}>
                      {location?.clickThroughUrl}
                    </LocationCardClickthrough>
                  </LocationCardDataLabel>
                ) : null}
                {location?.clickThroughCommunityPage ? (
                  <LocationCardDataLabel>
                    Clickthrough
                    <LocationCardClickthrough>
                      Opens to Page
                    </LocationCardClickthrough>
                  </LocationCardDataLabel>
                ) : null}
              </LocationCard>
            )
          })}
        </LocationCardsContainer>
      </Section>

      {!!isLoading && <Spinner />}

      <Modal
        contentWidth={400}
        onClose={handleOnClickDownloadOptions}
        visible={isDownloadOptionsVisible}
      >
        <Modal.Header>Download Metrics</Modal.Header>
        <Modal.Body
          style={{
            overflow: 'visible',
          }}
        >
          <DateRange
            endDate={endDate}
            labelEnd='To'
            labelStart='From'
            maxEndDate={moment(campaign.endDate).toDate()}
            minStartDate={moment(campaign.startDate).toDate()}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            startDate={startDate}
          />
          <DownloadMetricsActions>
            <StyledCopyToClipboard
              content={() => <Button appearance='ghost'>Copy CSV</Button>}
              contentOnCopy={() => <Button appearance='ghost'>Copied!</Button>}
              text={handleOnClickCopyCSV}
            />
            <Button onClick={handleOnClickDownloadCSV}>Download CSV</Button>
          </DownloadMetricsActions>
        </Modal.Body>
      </Modal>
    </MetricsBlocks>
  )
}

const MetricsBlocks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`

const Section = styled.div`
  margin-bottom: 5px;
`

const SectionTitle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 700;
  justify-content: space-between;
  margin-bottom: 5px;
`

const TotalsLargeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  min-height: 160px;
`

const TotalsLarge = styled(Well)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  font-size: 24px;
  justify-content: space-around;

  label {
    font-size: 14px;
    margin-bottom: 10px;
  }
`

const TotalsLargeValue = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;

  span {
    color: ${Colors.MINE_SHAFT};
    font-size: 14px;
    margin-left: 10px;
  }
`

const TotalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 500;
  gap: 5px;
  line-height: normal;
`

const ActivityGraphContainer = styled.div`
  align-items: center;
  border-radius: 6px;
  border: 1px solid ${Colors.ALTO};
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  max-height: 800px;
  min-height: 400px;
  padding: 20px;
`
const ActivityGraphHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const LocationCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
`
const LocationCard = styled(Well)`
  background-color: ${Colors.WHITE};
  flex: 1;
`

const LocationCardTitle = styled.div`
  align-items: center;
  color: ${Colors.PUNCH};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  gap: 10px;
  line-height: normal;
  margin-bottom: 20px;
`

const LocationCardData = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const LocationCardPreview = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    border-radius: 6px;
    border: 1px solid ${Colors.ALTO};
    max-width: 180px;
  }
`

const LocationCardDataLabel = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 5px;
  flex: 1 0 50%;
  margin-bottom: 20px;
`

const LocationCardClickthrough = styled.div`
  align-items: center;
  flex-direction: row;
  font-size: 16px;
  font-weight: 500;
  gap: 5px;
  line-height: normal;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
`

const DownloadsButton = styled.div`
  color: ${Colors.HAVELOCK_BLUE};
  cursor: pointer;
  font-weight: 500;
`

const DownloadMetricsActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 10px;

  button {
    width: 100%;
  }
`

const StyledCopyToClipboard = styled(CopyToClipboard)`
  width: 100%;
`
