import {
  ApiRequestOptions,
  ApiResponse,
  CacheOptions,
  StaticApiRequest,
  post,
} from '../api-base'

export interface GetUserInfoRequest {
  timezone: {
    name: string
    value: string
  }
  userId: string
  requestOptions?: ApiRequestOptions
}

export interface InfoData {
  content: string
  filename: string
}

export interface GetUserInfoResponse extends ApiResponse {
  data?: InfoData
  error?: any
}

export async function getUserInfo(
  request: GetUserInfoRequest
): Promise<GetUserInfoResponse> {
  const req: StaticApiRequest = {
    action: 'getUserInfo',
    timezone: request.timezone,
    userId: request.userId,
  }
  const reqString = JSON.stringify(req)
  let options = request?.requestOptions
  if (!options) {
    options = {
      useCache: CacheOptions.NEVER,
    }
  }
  try {
    const response = await post(req, options)
    console.log('response', response, reqString)
    return {
      data: response.data,
      error: response.error,
      ok: response.ok,
      requestString: reqString,
    }
  } catch (ex: any) {
    return { ok: false, error: ex, requestString: reqString }
  }
}

export default {
  getUserInfo,
}
